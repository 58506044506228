import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import KitchenIcon from '@mui/icons-material/Kitchen';
import BuildIcon from '@mui/icons-material/Build';
import PaymentIcon from '@mui/icons-material/Payment';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HandymanIcon from '@mui/icons-material/Handyman';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { Button, Grid } from '@mui/material';
import PageTitle from '../../components/PageTitle';
import PageCard from '../../components/PageCard';
import SuperAdminView from '../../components/SuperAdminView';
import { useActions } from '../../hooks';
import { enqueueSnackbarAction, requestAction } from '../../state';
import UpdateManualForm from './components/UpdateManualForm';
import FormDialog from '../../components/formdialog/FormDialog';

const fixedManualData = [{
    name: 'onboarding-manual-v2.0.pdf',
    title: 'Onboarding Manual',
    description: 'A detailed guide describing all steps necessary for your onboarding as a client.',
    icon: EmojiPeopleIcon,
}, {
    name: 'user-manual-1---installation-service-v1.0.pdf',
    title: 'User Manual 1: Installation & Service',
    description: 'This hardware manual describes how to properly install and maintain your fridges.',
    icon: BuildIcon,
}, {
    name: 'user-manual-2---using-the-intelligent-fridge-v1.0.pdf',
    title: 'User Manual 2: Using the Fridge',
    description: 'This manual describes how to use your fridges, including the Backoffice and the tagging process.',
    icon: KitchenIcon,
}, {
    name: 'side-pod-installation-guide',
    title: 'Side Pod Installation Guide',
    description: 'If your fridge comes with a sidepod, find here how install the sidepod on the side of your fridge.',
    icon: SendToMobileIcon,
}, {
    name: 'technical-maintenance-and-upgrade-manual',
    title: 'Technical Maintenance & Upgrades',
    description: 'This technical document covers basic repairs and upgrades for your Intelligent Fridge.',
    icon: HandymanIcon,
}, {
    name: 'adyen-guidelines-manual.pdf',
    title: 'Adyen Guidelines Manual',
    description: 'These guidelines contain everything needed to work with Adyen, one of our payment providers.',
    icon: PaymentIcon,
}, {
    name: 'App Feature Manual - Restocking Flow V1.01.pdf',
    title: 'Restocking Flow',
    description: 'A manual describing exactly how to restock the fridge using your own mobile app.',
    icon: SmartphoneIcon,
}, {
    name: 'Fridge Screen Manual - Configuration.pdf',
    title: 'Fridge Screen Manual',
    description: 'This manual explains how to configure the screen, and contains guidelines for the background image.',
    icon: ListAltIcon,
}, {
    name: 'Network Onepager - Facilities.pdf',
    title: 'Network Onepager',
    description: 'This short document contains all information needed to give your fridge proper internet access.',
    icon: CloudDoneIcon,
}];

const ManualVersion = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin: 8px;
    color: ${({ theme }) => theme.palette.text.hint};
`;
const UploadManualButton = styled(Button)`
    margin-bottom: 16px;
`;
const StyledGrid = styled(Grid)`
    .MuiGrid-item {
        width: 100%;
    }
`;

function Manuals() {
    const [isLoading, setIsLoading] = useState(false);
    const [manuals, setManuals] = useState(fixedManualData);
    const [closed, setClosed] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);

    const [request, enqueueSnackbar] = useActions([requestAction, enqueueSnackbarAction]);

    const merchantName = useSelector(state => state.configuration.merchant.name);

    const updateManuals = useCallback((newManualData) => {
        // Merge the fixed/existing manual data with new manual data
        const finalManualData =
            manuals.map(manual => (
                {
                    ...manual,
                    ...newManualData.find(newManual => newManual?.name === manual?.name),
                }
            ));

        setManuals(finalManualData);
    }, [manuals]);

    const openUploadDialog = useCallback(() => {
        setClosed(false);
        setShowUploadDialog(true);
    }, [closed, showUploadDialog]);

    const closeFormDialog = useCallback(() => {
        setClosed(true);
        setTimeout(() => {  // So we don't see flicker on close animation
            setShowUploadDialog(false);
        }, 500);
    }, [closed, showUploadDialog]);

    useEffect(() => {
        setIsLoading(true);

        const manualNames = fixedManualData.map(e => e.name);

        request({ // Request all the manuals
            body: manualNames,
            path: '/support/forward/files/versioned/all/latest',
        })
            .then(({ data }) => {
                const missingManuals = fixedManualData.filter(
                    m => !data.find(m2 => m2?.name === m?.name),
                );

                data && updateManuals(data);

                missingManuals.forEach(m => {
                    enqueueSnackbar(`Can't find manual ${m.title}`, { variant: 'error' });
                });
            })
            .catch(() => {
                enqueueSnackbar('Failed to fetch manuals', { variant: 'error' });
            })
            .finally(() => setIsLoading(false)); // So we don't see flicker on close animation
    }, []);

    return (
        <>
            <PageTitle
                title={`${merchantName} Manuals`}
                subtitle="View all our manuals"
            />
            <SuperAdminView>
                <UploadManualButton
                    color="primary"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    onClick={openUploadDialog}
                    disabled={isLoading}
                >
                    Upload manual
                </UploadManualButton>
                <FormDialog
                    title="Update manual"
                    open={!closed && showUploadDialog}
                    dialogMaxWidth="sm"
                    onClose={closeFormDialog}
                >
                    <UpdateManualForm
                        manuals={manuals}
                        updateManuals={updateManuals}
                        onClose={closeFormDialog}
                    />
                </FormDialog>
            </SuperAdminView>
            <StyledGrid container spacing={4}>
                {
                    manuals?.map(manual => (
                        <Grid item key={manual.name} lg={6}>
                            <PageCard
                                title={manual.title}
                                description={manual.description}
                                externalLink={manual.link}
                                Icon={manual.icon}
                                loading={isLoading}
                                // in disabled state when no link is present
                                disabled={!manual.link && !isLoading}
                            >
                                {manual.version &&
                                    <ManualVersion>v{manual.version}</ManualVersion>
                                }
                            </PageCard>
                        </Grid>
                    ))}

            </StyledGrid>
        </>
    );
}

export default Manuals;
