import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Handles scroll on first page load
function ScrollHandler({ children }) {
    const location = useLocation();

    useEffect(() => {
        const element = document.getElementById(location.hash.replace('#', ''));

        setTimeout(() => {
            window.scrollTo({
                behavior: element ? 'smooth' : 'auto',
                top: element ? element.offsetTop + 150 : 0,
            });
        }, 100);
    }, [location]);

    return children;
}

ScrollHandler.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ScrollHandler;
