import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ButtonBase, Card, CardContent, Typography, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { items as sidebarItems } from './Sidebar';

const StyledButtonBase = styled(ButtonBase)`
    width: 100%;

    .MuiPaper-root {
        // Disabled when no link is present
        ${({ theme, disableRipple }) => disableRipple && `
            background-color: ${theme.palette.background.disabled};
            border-color: ${theme.palette.background.disabled};
            color: ${theme.palette.text.secondary};
            &:hover {
                border-color: ${theme.palette.background.disabled};
                cursor: auto;
            }
        `}
    }
`;
const StyledCard = styled(Card)`
    width: 100%;
    border: 1px solid white;
    
    &:hover {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
`;
const StyledCardContent = styled(CardContent)`
    display: flex;
    padding: ${({ theme }) => theme.spacing(3, 2)};
`;
const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(0, 2, 0, 1)};
  
    .MuiSvgIcon-root {
        font-size: 2.5rem;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme }) => theme.spacing(0, 3, 0, 2)};

        .MuiSvgIcon-root {
            font-size: 2.8rem;
        }
    }
`;
const StyledCardTitle = styled(Typography)`
    font-weight: 500;
`;
const StyledCardDescription = styled(Typography)`
    font-weight: 300;
`;
const StyledDisabledWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: auto;
`;

function PageCard({ title, description, link, externalLink, Icon, children, disabled, loading }) {
    if (link && !sidebarItems.find(item => item.link === link)) {
        return null;
    }

    return (
        <StyledButtonBase
            component={link ? Link : 'a'}
            to={link}
            href={externalLink}
            target={link ? undefined : '_blank'}
            disableRipple={disabled}
        >
            <StyledCard>
                <StyledCardContent>
                    <StyledIconContainer>
                        {loading ?
                            <Skeleton animation="wave" variant="circular" width={45} height={45} />
                            : <Icon color="primary" />
                        }
                    </StyledIconContainer>
                    {loading ?
                        <Stack width="100%" height="95px" spacing={1}>
                            <Skeleton animation="wave" variant="text" height={24} width="60%" />
                            <Skeleton animation="wave" variant="text" height={20} width="95%" />
                            <Skeleton animation="wave" variant="text" height={20} width="95%" />
                            <Skeleton animation="wave" variant="text" height={20} width="50%" />
                            <Skeleton animation="wave" variant="text" height={20} width={40}
                                sx={{ position: 'absolute', top: 0, right: 8 }}
                            />
                        </Stack>
                        : <div>
                            <StyledCardTitle>
                                {title}
                            </StyledCardTitle>
                            <StyledCardDescription>
                                {description}
                            </StyledCardDescription>
                        </div>
                    }
                </StyledCardContent>
            </StyledCard>
            {!loading && children} {/* Hide content when loading */}
            {disabled && // Show error message when manual is not found
                <StyledDisabledWrapper>
                    <Tooltip disableInteractive arrow title="Manual not found" placement="top">
                        <ErrorOutlineIcon color="error" />
                    </Tooltip>
                </StyledDisabledWrapper>
            }
        </StyledButtonBase>
    );
}

PageCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    Icon: PropTypes.object.isRequired,
    link: PropTypes.string,
    externalLink: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
};

PageCard.defaultProps = {
    link: undefined,
    externalLink: undefined,
    children: undefined,
    disabled: false,
    loading: false,
};

export default PageCard;
