import { useState, useEffect } from 'react';
import { CssBaseline, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled.div`
    text-align: center;
    padding-top: 80px;
`;
const StyledH1 = styled(Typography)`
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
`;

function Page404() {
    const [timeLeft, setTimeLeft] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        if (!timeLeft) {
            navigate('/dashboard');
            return;
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return (
        <>
            <CssBaseline />
            <Grid container spacing={3}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <StyledContainer>
                                <StyledH1 variant="h1">404</StyledH1>
                                <Typography>
                                    The page you are looking for does not exist.<br />
                                    You will be redirected back to the dashboard
                                    in {timeLeft} seconds or you can click <a href="/dashboard">here</a>.
                                </Typography>
                            </StyledContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Page404;
