import { cloneElement, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { compiler } from 'markdown-to-jsx';
import { Link as RouterLink } from 'react-router-dom';
import { Collapse, Fade, IconButton, Typography, Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { useSnackbar } from 'notistack';
import { titleToId } from '../../../util/functions';

const StyledSectionTitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin: ${({ theme }) => theme.spacing(8, 0, 4, 0)};
`;
const StyledSectionTitle = styled(Typography)`
    scroll-margin-top: -150px;
    font-weight: 500;

    &:first-of-type {
        margin-top: 0;
    }
`;
const StyledQuestionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        &:hover {
            color: ${({ theme }) => theme.palette.text.secondary};
        }
    }
`;
const StyledQuestion = styled(Typography)`
    user-select: none;
    cursor: pointer;
    scroll-margin-top: -150px;
`;
const StyledIconButton = styled(IconButton)`
    margin-left: ${({ theme }) => theme.spacing(2)};
`;
const StyledAnswer = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.secondary};
    
    p {
        margin: ${({ theme }) => theme.spacing(1, 0)};
    }
    
    table {
        max-width: 100%;
        border-collapse: collapse;
        margin: ${({ theme }) => theme.spacing(2, 0)};
    }
    
    img {
        max-width: 100%;
        max-height: 800px;
        margin: ${({ theme }) => theme.spacing(2, 0)};
    }
    
    th, td {
        border: 1px solid ${({ theme }) => theme.palette.divider};
        padding: ${({ theme }) => theme.spacing(1)};
    }
    
`;

function copyLink(id, enqueueSnackbar) {
    navigator.clipboard.writeText(
        `${window.location.href}#${id}`.replace(window.location.hash, ''),
    );
    enqueueSnackbar('Link copied', { variant: 'success' });
}

function SectionTitle({ children, locationHash, ...props }) {
    const [showLink, setShowLink] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const id = titleToId(children[0]);

    return (
        <StyledSectionTitleContainer
            onMouseEnter={() => setShowLink(true)}
            onMouseLeave={() => setShowLink(false)}
        >
            <StyledSectionTitle id={id} {...props} variant="h3">
                {children}
            </StyledSectionTitle>
            <Fade in={showLink}>
                <StyledIconButton size="small" onClick={() => copyLink(id, enqueueSnackbar)}>
                    <LinkIcon />
                </StyledIconButton>
            </Fade>
        </StyledSectionTitleContainer>
    );
}

function Question({ children, locationHash, ...props }) {
    const [expanded, setExpanded] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const id = titleToId(children[0]);

    useEffect(() => {
        if (locationHash?.replace('#', '') === id) {
            setExpanded(true);
        }
    }, [locationHash]);

    return (
        <>
            <StyledQuestionContainer
                onMouseEnter={() => setShowLink(true)}
                onMouseLeave={() => setShowLink(false)}
            >
                <StyledQuestion
                    {...props}
                    id={id}
                    variant="h4"
                    onClick={() => setExpanded(prev => !prev)}
                >
                    {children[0]}
                </StyledQuestion>
                <Fade in={showLink}>
                    <StyledIconButton size="small" onClick={() => copyLink(id, enqueueSnackbar)}>
                        <LinkIcon />
                    </StyledIconButton>
                </Fade>
            </StyledQuestionContainer>
            <Collapse in={expanded}>
                <StyledAnswer component="div" variant="body2">{children[1]}</StyledAnswer>
            </Collapse>
        </>
    );
}

function MarkdownLink({ children, href }) {
    if (href.startsWith('/')) {
        return <Link component={RouterLink} to={href}>{children}</Link>;
    }

    return <Link component="a" target="_blank" href={href}>{children}</Link>;
}

function FaqQuestions({ markdown, locationHash }) {
    const compiled = compiler(markdown, {
        overrides: {
            h3: SectionTitle,
            h4: Question,
            a: MarkdownLink,
        },
        wrapper: null,
    });

    /**
     * This method makes sure that we add all children, so that multi line is supported more easily
     * and also tables, images, etc., are accounted and displayed properly
     * @param type that comes in
     * @param index at which index we are currently in terms of all the q/a
     * @returns {*[]|*}
     */
    function customTypeHandling(type, index) {
        if (type === Question) {
            return [];
        }

        if (type === 'table') {
            // In the case of table return the entire object otherwise it will be rendered without
            // the table tag, which is no bueno
            return compiled[index + 1];
        }

        const children = [];
        const slicedCompiledArray = compiled.slice(index);
        slicedCompiledArray.every((item, idx) => {
            // The first question we encounter is the one we'll be adding an answer for, so it
            // should be added
            if (idx === 0 && item.type === Question) {
                return true;
            }

            // Any other question or section title is the next one, this is when we're done
            if (idx > 0 && (item.type === Question || item.type === SectionTitle)) {
                return false;
            }

            children.push(item);
            return true;
        });

        return children;
    }

    // Add answers to child of question, so we have it in one component
    return compiled.map((item, index) => {
        const type = compiled?.[index + 1]?.type;
        // Anything that isn't a header we define will be considered content
        if (item.props?.id && (type !== 'h3' && type !== 'h4')) {
            const children = customTypeHandling(type, index);
            return cloneElement(item, {
                locationHash,
                children: [...item.props.children, children],
            });
        }

        if (item.type !== 'h3' && item.type !== 'h4') {
            return null;
        }

        return item;
    });
}

export default FaqQuestions;
