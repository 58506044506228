/* eslint-disable no-param-reassign */
import * as Cookie from 'tiny-cookie';
import HTTPRequest from '../../api/HTTPRequest';
import { SESSION_DURATION } from '../../config';
import { getDomain } from '../../util/functions';

const ACCESS_TOKEN_KEY = window.location.hostname.includes('.test.')
    ? 'test-session-accesstoken'
    : 'session-accesstoken';

const initialState = {
    id: null,
    firstName: null,
    lastName: null,
    emailAddress: null,
    languageCode: 'en',
    roles: [],
    accessToken: null,
};

const oldSession = {
    state: {
        session: {
            ...initialState,
            accessToken: Cookie.get(ACCESS_TOKEN_KEY),
        },
        isLoadingFinished: false,
    },

    getUserId() {
        return this.state.session.id;
    },

    getFirstName() {
        return this.state.session.firstName;
    },

    getLastName() {
        return this.state.session.lastName;
    },

    getFullName() {
        return `${this.state.session.firstName} ${this.state.session.lastName}`;
    },

    getEmail() {
        return this.state.session.emailAddress;
    },

    getAccessToken() {
        return this.state.session.accessToken;
    },

    isAuthenticated() {
        return this.state.session.accessToken && this.state.session.id;
    },

    isSuperAdmin() {
        return this.state.session.roles.includes('ROLE_ADMIN');
    },

    isMerchantAdmin() {
        return !this.isSuperAdmin() && this.state.session.roles.includes('ROLE_MERCHANT_ADMIN');
    },

    isNoAdmin() {
        const roles = this.state.session?.roles;

        return !roles || !roles.some(r => r.endsWith('_ADMIN'));
    },

    loginWithCredentials(emailAddress, password) {
        return login(this.state, { emailAddress, password });
    },

    loginWithToken() {
        return login(this.state, { accessToken: this.state.session?.accessToken ?? 'dummy' })
            .catch(() => {
                Cookie.remove(ACCESS_TOKEN_KEY);
                Cookie.remove(ACCESS_TOKEN_KEY, { domain: getDomain() });

                throw new Error('Login failed');
            });
    },

    logout() {
        Cookie.remove(ACCESS_TOKEN_KEY);
        Cookie.remove(ACCESS_TOKEN_KEY, { domain: getDomain() });

        return HTTPRequest({ path: '/auth/action-logout', accessToken: this.state.session })
            .catch(() => null)
            .finally(() => {
                this.state.session = { ...initialState };
            });
    },

    updatePassword() {
        console.warn('Not implemented for local client without Keycloak');
    },
};

function login(state, body) {
    return HTTPRequest({ path: '/auth/action-login', body })
        .then(({ data }) => {
            const { accessToken, roles } = data;

            if (!roles.includes('ROLE_ADMIN') && !roles.includes('ROLE_MERCHANT_ADMIN')) {
                // eslint-disable-next-line
                throw { data: 'not admin' };  // picked up by Login view
            }

            Cookie.set(ACCESS_TOKEN_KEY, accessToken, {
                expires: SESSION_DURATION,
                domain: getDomain(),
            });

            state.session = { ...data, emailAddress: body.emailAddress };
        });
}

export default oldSession;