import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Typography, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { setSidebarOpenAction } from '../state';
import { useActions } from '../hooks';

const StyledTitle = styled(Typography)`
    padding: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.secondary}CC;
    margin: ${({ theme }) => theme.spacing(0.5, 0, 0.5, 4.5)};
`;
const StyledDivider = styled.div`
    width: 100%;
    height: ${({ theme }) => theme.spacing(3)};
`;
const StyledLink = styled(({ isLinkActive, ...rest }) => <ListItem {...rest} />)`
    text-decoration: none;
    padding: ${({ theme }) => theme.spacing(0.5, 0, 0.5, 2.5)};
  
    &:hover {
        background-color: initial;

        .MuiListItemText-primary, .MuiListItemIcon-root {
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }
  
    .MuiListItemText-primary {
        padding: 0;
        font-size: 15px;
        color: ${({ theme }) => theme.palette.text.secondary}CC;
    
        ${({ isLinkActive, theme }) => isLinkActive && css`
            color: ${theme.palette.primary.main};
        `}
    }
`;
const StyledLinkIcon = styled(({ isLinkActive, ...rest }) => <ListItemIcon {...rest} />)`
    display: flex;
    justify-content: center;
    margin-right: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => theme.palette.text.secondary}CC;
    
    ${({ isLinkActive, theme }) => isLinkActive && css`
        color: ${theme.palette.primary.main};
    `}
`;

const types = {
    TITLE: 'title',
    DIVIDER: 'divider',
    LINK: 'link',
};

function SidebarTitle({ label }) {
    return (
        <StyledTitle>
            {label}
        </StyledTitle>
    );
}

function SidebarLink({ link, label, Icon }) {
    const location = useLocation();
    const isSidebarOpened = useSelector(state => state.sidebar.isSidebarOpened);
    const setSidebarOpen = useActions(setSidebarOpenAction);
    const isLinkActive = link && location.pathname === link;

    return (
        <StyledLink
            component={link && Link}
            to={link}
            isLinkActive={isLinkActive}
            onClick={() => isSidebarOpened && setSidebarOpen(false)}
            button
            disableRipple
        >
            <StyledLinkIcon isLinkActive={isLinkActive}>
                <Icon />
            </StyledLinkIcon>
            <ListItemText primary={label} />
        </StyledLink>
    );
}

function SidebarItem({ type, ...props }) {
    switch (type) {
        case types.TITLE:
            return <SidebarTitle {...props} />;
        case types.DIVIDER:
            return <StyledDivider />;
        default:
            return <SidebarLink {...props} />;
    }
}

SidebarItem.propTypes = {
    type: PropTypes.string.isRequired,
    link: PropTypes.string,
    label: PropTypes.string,
    Icon: PropTypes.object,
};

SidebarItem.defaultProps = {
    link: null,
    label: null,
    Icon: null,
};

export { types };
export default SidebarItem;