import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button, DialogActions } from '@mui/material';
import MaybeLoading from '../MaybeLoading';

const StyledDialogActions = styled(DialogActions)`
    padding: ${({ theme }) => theme.spacing(2)};
`;

function FormDialogButtons({ isLoading, closeDialog, disabled }) {
    return (
        <StyledDialogActions>
            <Button color="primary" onClick={() => closeDialog(false)}>
                Cancel
            </Button>
            <MaybeLoading isLoading={isLoading} size="small">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={disabled}
                >
                    Submit
                </Button>
            </MaybeLoading>
        </StyledDialogActions>
    );
}

FormDialogButtons.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

FormDialogButtons.defaultProps = {
    disabled: false,
};

export default FormDialogButtons;
