import { Outlet, Navigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Container, CssBaseline } from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar';
import session from '../auth/session/session';

const Root = styled.div`
    display: flex;
    max-width: 100vw;
    overflow-x: hidden;
`;
const Content = styled.div`
    flex-grow: 1;
    padding-bottom: ${({ theme }) => theme.spacing(6)};
    min-height: 100vh;
    overflow-y: hidden;
`;
const FakeToolBar = styled.div`
    margin-top: 1px;
    margin-bottom: 1px;
    ${({ theme }) => theme.mixins.toolbar}
`;

function Layout() {
    const location = useLocation();

    if (!session.isAuthenticated()) {
        return (
            <Navigate replace to={{ pathname: '/', state: { from: location, isRedirect: true } }} />
        );
    }

    return (
        <Root>
            <CssBaseline />
            <Header />
            <Sidebar />
            <Content>
                <FakeToolBar />
                <Container maxWidth="md">
                    <Outlet />
                </Container>
            </Content>
        </Root>
    );
}

export default Layout;
