import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import logger from '../middleware/logger';
import { snackbarReducer } from '../state/ui/snackbar';
import { sidebarReducer } from '../state/ui/sidebar';
import { configurationReducer } from '../state/configuration/configuration';
import { keycloakReducer } from '../state/auth/keycloak';

const store = createStore(
    combineReducers({
        sidebar: sidebarReducer,
        snackbar: snackbarReducer,
        configuration: configurationReducer,
        keycloak: keycloakReducer,
    }),
    applyMiddleware(ReduxThunk, logger),
);

export default store;
