import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { Button, TextareaAutosize, Typography } from '@mui/material';
// import { Prompt } from 'react-router-dom';  // not supported at the moment (removed in v6, but should be back)
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { getRegisterInput } from '../../../util/formValidators';
import MaybeLoading from '../../../components/MaybeLoading';
import { useActions } from '../../../hooks';
import { requestAction } from '../../../state';
import FaqQuestions from './FaqQuestions';
// import useExitPrompt from '../../../hooks/useExitPrompt';

const StyledButtonContainer = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(6)};
`;
const StyledButton = styled(Button)`
    margin-right: ${({ theme }) => theme.spacing(1)};
`;
const StyledTextareaWrapper = styled.div`
    display: ${({ showPreview }) => showPreview ? 'none' : 'initial'};
`;
const StyledTextareaAutosize = styled(TextareaAutosize)`
    width: 100%;
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
    overflow: auto !important;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.palette.divider};
    padding: ${({ theme }) => theme.spacing(1)};
    
    &:focus {
        outline: none;
    }
`;
const StyledPreviewContainer = styled.div`
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing(1)};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme }) => theme.spacing(2)};
    }
`;
const StyledPreviewText = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

function FaqEditor({ markdown, setMarkdown, setEditing }) {
    const [isLoading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [currentMarkdown, setCurrentMarkdown] = useState(markdown);
    const { watch, register, getValues, handleSubmit } = useForm();
    const request = useActions(requestAction);
    const registerInput = getRegisterInput(register, watch, true);
    // const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

    const handleShowPreview = useCallback(() => {
        setShowPreview(prev => !prev);
        setCurrentMarkdown(getValues('content'));
    }, [getValues]);

    const submitHandler = useCallback(body => {
        setLoading(true);

        request({
            body,
            path: '/support/forward/pagecontent/faq/action-update',
            errorMessage: 'Failed to update FAQ content',
        })
            .then(({ content }) => {
                setMarkdown(content);
                setEditing(false);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <form
            noValidate
            onSubmit={handleSubmit(submitHandler)}
            // onChange={() => setShowExitPrompt(true)}
        >
            <StyledButtonContainer>
                <MaybeLoading isLoading={isLoading} size="small">
                    <StyledButton
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </StyledButton>
                </MaybeLoading>
                <StyledButton
                    variant="outlined"
                    startIcon={showPreview ? <EditIcon /> : <VisibilityIcon />}
                    onClick={handleShowPreview}
                >
                    {showPreview ? 'Edit' : 'Preview'}
                </StyledButton>
                <StyledButton
                    variant="outlined"
                    onClick={() => setEditing(false)}
                >
                    Cancel
                </StyledButton>
            </StyledButtonContainer>
            <StyledTextareaWrapper showPreview={showPreview}>
                <StyledTextareaAutosize
                    minRows={8}
                    defaultValue={markdown}
                    { ...registerInput('content') }
                />
            </StyledTextareaWrapper>
            {
                showPreview &&
                <StyledPreviewContainer>
                    <StyledPreviewText variant="body2" color="primary">
                        PREVIEW
                    </StyledPreviewText>
                    <div>
                        <FaqQuestions markdown={currentMarkdown} />
                    </div>
                </StyledPreviewContainer>
            }
            {/* <Prompt */}
            {/*    when={showExitPrompt} */}
            {/*    message="Content was not saved, are you sure you want to leave?" */}
            {/* /> */}
        </form>
    );
}

export default FaqEditor;
