import { forwardRef, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { matchSorter } from 'match-sorter';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useNavigate } from 'react-router-dom';
import { Divider, InputBase, List, Paper, Popper, Typography, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useActions } from '../hooks';
import { requestAction } from '../state';

const StyledAutocomplete = styled(Autocomplete)`
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    margin-right: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
  
    &:hover {
        background-color: rgba(0, 0, 0, 0.20);
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        width: auto;
    }
`;
const StyledSearchIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    pointer-events: none;
    padding: ${({ theme }) => theme.spacing(0, 2)};
`;
const StyledSearchInput = styled(InputBase)`
    color: inherit;

    .MuiInputBase-input.MuiAutocomplete-input {
        width: 100%;
        transform: translateY(2px);
        padding: ${({ theme }) => theme.spacing(1, 1, 1, 7)};
        transition: ${({ theme }) => theme.transitions.create('width')};
      
        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 120px;
          
            &:focus {
                width: 190px;
            }
        }
    }
`;
const StyledPopper = styled(({ isLoading, noOptions, ...rest }) => <Popper {...rest} />)`
    width: 480px !important;
    right: -8px !important;
    top: 4px !important;
    max-width: calc(100vw - 24px);
  
    ul {
        margin: 0 !important;
    }
`;
const StyledListBoxContainer = styled.div`
    padding: 0;
    max-height: calc(100vh - 64px) !important;

    .MuiAutocomplete-option {
        padding: ${({ theme }) => theme.spacing(0.5, 0)};
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        .MuiAutocomplete-option {
            padding: ${({ theme }) => theme.spacing(0.5, 2)};
        }
    }
`;
const StyledGroupContainer = styled.div`
   padding: ${({ theme }) => theme.spacing(1, 2, 0, 2)};
`;
const StyledOptionContainer = styled.div`
    display: flex;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(0.5, 0)};
`;
const StyledCategoryContainer = styled.div`
    width: 25%;
    text-align: right;
    color: ${({ theme }) => theme.palette.text.hint};
    padding-right: ${({ theme }) => theme.spacing(1)};
`;
const StyledCategory = styled(Typography)`
    overflow: hidden;
    text-overflow: ellipsis;
`;
const StyledTitleDescriptionContainer = styled.div`
    width: 75%;
    padding-left: ${({ theme }) => theme.spacing(1)};
`;
const StyledTitle = styled(Typography)`
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;
const StyledDescription = styled(Typography)`
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.text.hint};
`;
const StyledSpan = styled.span`
    ${({ highlight, theme }) => highlight && css`
        background-color: ${theme.palette.primary.main}30;
    `}
`;

function highlightText(text, inputValue) {
    return parse(text, match(text, inputValue)).map((part, index) => (
        <StyledSpan key={index} highlight={part.highlight}>{part.text}</StyledSpan>
    ));
}

function SearchGroup({ group, children }) {
    let name;

    switch (group) {
        case 'faq':
            name = 'FAQ';
            break;
        default:
            name = group;
    }

    return (
        <StyledGroupContainer key={group}>
            <Typography variant="h6" color="primary">{name}</Typography>
            <Divider />
            {children}
        </StyledGroupContainer>
    );
}

function SearchOption({ option, inputValue, ...props }) {
    return (
        <StyledOptionContainer {...props}>
            <StyledCategoryContainer>
                <StyledCategory>
                    {highlightText(option.category, inputValue)}
                </StyledCategory>
            </StyledCategoryContainer>
            <Divider flexItem orientation="vertical" />
            <StyledTitleDescriptionContainer>
                <StyledTitle>
                    {highlightText(option.title, inputValue)}
                </StyledTitle>
                <StyledDescription noWrap>
                    {highlightText(option.description, inputValue)}
                </StyledDescription>
            </StyledTitleDescriptionContainer>
        </StyledOptionContainer>
    );
}

const ListboxComponent = forwardRef(({ children, ...rest }, ref) => (
    <StyledListBoxContainer ref={ref} {...rest}>
        <List>
            {children}
        </List>
    </StyledListBoxContainer>
));

function PopperComponent(params) {
    return (
        <StyledPopper {...params} placement="bottom-end" />
    );
}

function PaperComponent(params) {
    return (
        <Paper {...params} elevation={2} variant="elevation" />
    );
}

function HeaderSearch() {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [refreshValue, setRefreshValue] = useState(false);
    const navigate = useNavigate();
    const request = useActions(requestAction);

    const handleChange = useCallback((e, v) => {
        if (!v?.path) return;

        navigate(v.path);
        setRefreshValue(prev => !prev);
    }, []);

    const filterOptions = useCallback((options, { inputValue }) => (
        matchSorter(options, inputValue, {
            keys: ['page', 'category', 'title', 'description'],
        }).slice(0, 5)
    ), []);

    useEffect(() => {
        setLoading(true);

        request({ path: '/support/forward/search/allpages' })
            .then(result => setData(result.data))
            .finally(() => setLoading(false));
    }, []);

    return (
        <StyledAutocomplete
            freeSolo
            key={`headersearch-${refreshValue}`}
            forcePopupIcon={false}
            options={data}
            getOptionLabel={v => v?.title ?? ''}
            onChange={handleChange}
            loading={isLoading}
            filterOptions={filterOptions}
            groupBy={option => option.page}
            renderGroup={SearchGroup}
            renderOption={(props, option, { inputValue }) => (
                <SearchOption {...props} option={option} inputValue={inputValue} />
            )}
            renderInput={({ InputLabelProps, InputProps, ...params }) => (
                <>
                    <StyledSearchIcon>
                        <SearchIcon />
                    </StyledSearchIcon>
                    <StyledSearchInput
                        {...InputProps}
                        {...params}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        placeholder="Search..."
                    />
                </>
            )}
            PopperComponent={PopperComponent}
            PaperComponent={PaperComponent}
            ListboxComponent={ListboxComponent}
        />
    );
}

export default HeaderSearch;
