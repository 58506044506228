import { useEffect, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { useKeycloak as useKeycloakWeb } from '@react-keycloak/web';
import CenteredLoader from './CenteredLoader';
import { KEYCLOAK_DISABLED } from '../config';
import session from '../auth/session/session';

const useKeycloak = KEYCLOAK_DISABLED
    ? () => ({ keycloak: {}, initialized: false })
    : useKeycloakWeb;

function StartupDataLoader({ children }) {
    const [oldAuthenticated, setOldAuthenticated] = useState(false);
    const [oldAuthFailed, setOldAuthFailed] = useState(false);

    const { keycloak, initialized } = useKeycloak();
    const authenticated = (!KEYCLOAK_DISABLED && initialized && keycloak.authenticated)
        || (KEYCLOAK_DISABLED && oldAuthenticated);

    useEffect(() => {  // Only for auth without keycloak
        KEYCLOAK_DISABLED && session.loginWithToken()
            .then(() => setOldAuthenticated(true))
            .catch(() => setOldAuthFailed(true));  // Go to login page
    }, []);

    if (!oldAuthFailed && !authenticated) {
        return (
            <>
                <CssBaseline />
                <CenteredLoader absolute />
            </>
        );
    }

    return children;
}

export default StartupDataLoader;
