import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import {
    AppBar,
    IconButton,
    Toolbar,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Logo from './Logo';
import ProfileMenu from './ProfileMenu';
import createEither from './createEither';
import { setSidebarOpenAction } from '../state';
import { useActions } from '../hooks';
import HeaderSearch from './HeaderSearch';
import { getAdminUrl } from '../util/functions';

const StyledAppBar = styled(AppBar)`
    width: 100%;
    border-left: none;
    border-right: none;
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
    background-color: ${({ theme }) => theme.palette.background.default};
`;
const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding-left: ${({ theme }) => theme.spacing(2)};
        padding-right: ${({ theme }) => theme.spacing(2)};
    }
`;
const StyledSideContainer = styled.div`
    display: flex;
    align-items: center;
`;
const StyledRightContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-left: ${({ theme }) => theme.spacing(1)};
`;
const StyledLogo = styled(Logo)`
    height: 24px;
    margin-left: ${({ theme }) => theme.spacing(2)};
`;


const MenuButtonOrLogo = createEither({
    Left: ({ setSidebarOpen }) => (
        <IconButton onClick={() => setSidebarOpen(true)} size="large">
            <MenuIcon />
        </IconButton>
    ),
    Right: () => (
        <StyledLogo />
    ),
    isRight: ({ isDesktop }) => isDesktop,
});

// TODO: implement search (https://www.algolia.com/products/search/ ??)
function Header() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const setSidebarOpen = useActions(setSidebarOpenAction);

    return (
        <StyledAppBar position="fixed" color="transparent" variant="outlined" elevation={0}>
            <StyledToolbar>
                <StyledSideContainer>
                    <MenuButtonOrLogo
                        setSidebarOpen={setSidebarOpen}
                        isDesktop={isDesktop}
                    />
                </StyledSideContainer>
                <StyledRightContainer>
                    <HeaderSearch />
                    {
                        isDesktop &&
                        <Tooltip title="Intelligent Fridges Backoffice">
                            <IconButton
                                color="inherit"
                                href={
                                    getAdminUrl(window.location.hostname)
                                }
                                size="large"
                            >
                                <OpenInNewIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <ProfileMenu />
                </StyledRightContainer>
            </StyledToolbar>
        </StyledAppBar>
    );
}

export default Header;
