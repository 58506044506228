export const initialState = {
    notifications: [],
};

export const ENQUEUE = 'snackbar/ENQUEUE';
export const REMOVE = 'snackbar/REMOVE';

export function snackbarReducer(state = initialState, { type, notification, key }) {
    switch (type) {
        case ENQUEUE:
            return {
                ...state,
                notifications: [...state.notifications, notification],
            };
        case REMOVE:
            return {
                ...state,
                notifications: state.notifications.filter(n => n.key !== key),
            };
        default:
            return state;
    }
}

export function enqueueSnackbarAction(message, options) {
    return {
        type: ENQUEUE,
        notification: {
            message,
            options,
            key: options.key || new Date().getTime() + Math.random(),
        },
    };
}

export function removeSnackbarAction(key) {
    return {
        key,
        type: REMOVE,
    };
}
