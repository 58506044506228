import { isEqual } from 'lodash';
import tinycolor from 'tinycolor2';

function titleToId(title) {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
}

function setBrowserTitle(title) {
    const browserTitle = document.getElementById('browser_title');
    browserTitle.innerText = title;
}

function setFavicon(link) {
    const favicon = document.getElementById('favicon');
    favicon.href = link;
}

function getNewStateOnlyOnChange(oldState, newState) {
    return isEqual(oldState, newState) ? oldState : newState;
}

function getAdminUrl(hostName) {
    const subdomains = hostName.split('.');
    if (subdomains[0] === 'localhost') {
        return `http://${hostName}:3002`;
    }
    if (subdomains[0] === 'support'
        && (subdomains?.[1] === 'intelligentfridges' || subdomains?.[2] === 'intelligentfridges')) {
        return `https://${hostName.replace('support.', 'admin.')}`;
    }
    return `https://${hostName.replace('support.','')}`;
}

function mergeTransparentColorWithWhite(colorHexString) {
    const color = tinycolor(colorHexString).toRgb();
    const result = {};

    result.r = ((1 - color.a) * 255) + (color.a * color.r);
    result.g = ((1 - color.a) * 255) + (color.a * color.g);
    result.b = ((1 - color.a) * 255) + (color.a * color.b);
    result.r = (result.r > 255) ? 255 : result.r;
    result.g = (result.g > 255) ? 255 : result.g;
    result.b = (result.b > 255) ? 255 : result.b;

    return tinycolor(result).toHexString();
}

function getDomain() {
    return window.location.hostname.split('.').slice(-2).join('.');
}

export {
    titleToId,
    setBrowserTitle,
    setFavicon,
    getNewStateOnlyOnChange,
    getAdminUrl,
    mergeTransparentColorWithWhite,
    getDomain,
};
