import styled from '@emotion/styled';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import createEither from './createEither';

const StyledSvg = styled.svg`
    .cls-1 {
        fill: ${({ theme }) => theme.palette.primary.main};
    }
`;

const MaybeDefaultLogo = createEither({
    Left: DefaultLogo,
    Right: AltLogo,
    isRight: ({ logoLink }) => !isUndefined(logoLink),
});

function AltLogo({ logoLink, className }) {
    return (
        <img src={logoLink} alt="logo" className={className} />
    );
}

function DefaultLogo({ className }) {
    return (
        <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.91 30.67" className={className}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <polygon points="0 18.57 8.86 18.57 8.86 20.47 2.09 20.47 2.09 23.72 8.09 23.72 8.09 25.62 2.09 25.62 2.09 30.47 0 30.47 0 18.57" />
                    <path d="M18.19,18.57h5.3a4.8,4.8,0,0,1,3.44,1.19,3.62,3.62,0,0,1,1,2.56v0A3.56,3.56,0,0,1,25.07,26l3.22,4.51H25.82L22.9,26.32H20.28v4.15H18.19Zm5.15,5.9c1.49,0,2.45-.78,2.45-2v0c0-1.27-.92-2-2.47-2h-3v4Z" />
                    <rect x="37.64" y="18.57" width="2.09" height="11.9" />
                    <path d="M49.94,18.57h4.44c3.74,0,6.32,2.57,6.32,5.91v0c0,3.35-2.58,5.95-6.32,5.95H49.94Zm4.44,10a3.88,3.88,0,0,0,4.13-4v0a3.91,3.91,0,0,0-4.13-4.05H52v8.09Z" />
                    <path d="M69.75,24.55v0a6.06,6.06,0,0,1,6.14-6.15A6.42,6.42,0,0,1,80.46,20l-1.33,1.58a4.67,4.67,0,0,0-3.33-1.27,4,4,0,0,0-3.86,4.19v0a4,4,0,0,0,4,4.25,4.88,4.88,0,0,0,2.87-.9V25.64h-3V23.82h5.06v5a7.58,7.58,0,0,1-5,1.85,5.9,5.9,0,0,1-6.17-6.12" />
                    <polygon points="90.64 18.57 99.46 18.57 99.46 20.44 92.73 20.44 92.73 23.53 98.7 23.53 98.7 25.4 92.73 25.4 92.73 28.6 99.55 28.6 99.55 30.47 90.64 30.47 90.64 18.57" />
                    <path d="M108.27,28.73l1.26-1.49a5.52,5.52,0,0,0,3.76,1.55c1.29,0,2.1-.6,2.1-1.5v0c0-.85-.47-1.31-2.68-1.82-2.53-.62-4-1.36-4-3.56v0c0-2,1.7-3.45,4.06-3.45a6.66,6.66,0,0,1,4.32,1.49L116,21.48a5.42,5.42,0,0,0-3.23-1.23c-1.23,0-1.94.63-1.94,1.41v0c0,.91.54,1.32,2.82,1.87,2.52.61,3.83,1.51,3.83,3.48v0c0,2.22-1.76,3.55-4.25,3.55a7.33,7.33,0,0,1-5-1.91" />
                    <rect y="0.2" width="2.09" height="11.9" />
                    <polygon points="12.31 0.2 14.25 0.2 20.62 8.43 20.62 0.2 22.68 0.2 22.68 12.1 20.93 12.1 14.37 3.64 14.37 12.1 12.31 12.1 12.31 0.2" />
                    <polygon points="35.7 2.14 31.93 2.14 31.93 0.2 41.58 0.2 41.58 2.14 37.81 2.14 37.81 12.1 35.7 12.1 35.7 2.14" />
                    <polygon points="50.83 0.2 59.65 0.2 59.65 2.07 52.92 2.07 52.92 5.17 58.89 5.17 58.89 7.04 52.92 7.04 52.92 10.23 59.74 10.23 59.74 12.1 50.83 12.1 50.83 0.2" />
                    <polygon points="69.26 0.2 71.35 0.2 71.35 10.2 77.6 10.2 77.6 12.1 69.26 12.1 69.26 0.2" />
                    <polygon points="86.82 0.2 88.91 0.2 88.91 10.2 95.17 10.2 95.17 12.1 86.82 12.1 86.82 0.2" />
                    <rect x="104.5" y="0.2" width="2.09" height="11.9" />
                    <path d="M116.28,6.19v0A6.06,6.06,0,0,1,122.42,0,6.46,6.46,0,0,1,127,1.61l-1.33,1.58a4.67,4.67,0,0,0-3.33-1.27,4,4,0,0,0-3.86,4.2v0a4,4,0,0,0,4,4.25,4.88,4.88,0,0,0,2.87-.9V7.27h-3V5.46h5.06v5a7.53,7.53,0,0,1-5,1.86,5.91,5.91,0,0,1-6.17-6.12" />
                    <polygon points="137.17 0.2 145.99 0.2 145.99 2.07 139.26 2.07 139.26 5.17 145.23 5.17 145.23 7.04 139.26 7.04 139.26 10.23 146.08 10.23 146.08 12.1 137.17 12.1 137.17 0.2" />
                    <polygon points="155.6 0.2 157.54 0.2 163.91 8.43 163.91 0.2 165.97 0.2 165.97 12.1 164.22 12.1 157.66 3.64 157.66 12.1 155.6 12.1 155.6 0.2" />
                    <polygon points="178.99 2.14 175.22 2.14 175.22 0.2 184.87 0.2 184.87 2.14 181.1 2.14 181.1 12.1 178.99 12.1 178.99 2.14" />
                    <path className="cls-1" d="M126.32,29.24l1.09-1.3a3.84,3.84,0,0,0,2.5,1c.76,0,1.22-.3,1.22-.8v0c0-.47-.29-.71-1.7-1.07-1.69-.43-2.78-.9-2.78-2.56v0c0-1.52,1.23-2.53,2.94-2.53A4.89,4.89,0,0,1,132.71,23l-1,1.39a3.91,3.91,0,0,0-2.18-.83c-.71,0-1.08.33-1.08.73v0c0,.55.36.73,1.81,1.1C132,25.89,133,26.5,133,28v0c0,1.67-1.27,2.61-3.08,2.61A5.33,5.33,0,0,1,126.32,29.24Z" />
                    <path className="cls-1" d="M134.44,26.87v-4.8h1.85v4.75c0,1.37.68,2.08,1.81,2.08s1.81-.69,1.81-2V22.07h1.85v4.74c0,2.54-1.43,3.79-3.69,3.79S134.44,29.34,134.44,26.87Z" />
                    <path className="cls-1" d="M143.64,22.07h3.43c2,0,3.22,1.19,3.22,2.9v0c0,1.94-1.51,2.95-3.4,2.95h-1.4v2.52h-1.85ZM147,26.3A1.3,1.3,0,0,0,148.42,25v0c0-.83-.58-1.27-1.5-1.27h-1.43V26.3Z" />
                    <path className="cls-1" d="M151.66,22.07h3.43c2,0,3.21,1.19,3.21,2.9v0c0,1.94-1.51,2.95-3.39,2.95h-1.4v2.52h-1.85ZM155,26.3A1.3,1.3,0,0,0,156.43,25v0c0-.83-.57-1.27-1.5-1.27h-1.42V26.3Z" />
                    <path className="cls-1" d="M159.3,26.29v0a4.47,4.47,0,0,1,8.93,0v0a4.47,4.47,0,0,1-8.93,0Zm7,0v0a2.56,2.56,0,0,0-2.55-2.64,2.52,2.52,0,0,0-2.52,2.62v0a2.56,2.56,0,0,0,2.55,2.64A2.52,2.52,0,0,0,166.3,26.29Z" />
                    <path className="cls-1" d="M169.88,22.07h3.83a3.35,3.35,0,0,1,2.45.85,2.64,2.64,0,0,1,.72,1.92v0a2.61,2.61,0,0,1-1.8,2.6l2,3H175l-1.8-2.69h-1.45v2.69h-1.84Zm3.71,4.08c.9,0,1.42-.48,1.42-1.19v0c0-.79-.55-1.2-1.45-1.2h-1.84v2.41Z" />
                    <path className="cls-1" d="M180.51,23.77H178v-1.7h7v1.7h-2.56v6.7h-1.84Z" />
                </g>
            </g>
        </StyledSvg>
    );
}

function Logo({ className }) {
    const logoLink = useSelector(state => state.configuration.headerLogoLink);

    return (
        <MaybeDefaultLogo logoLink={logoLink} className={className} />
    );
}


export default Logo;
