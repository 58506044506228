import PropTypes from 'prop-types';
import session from '../auth/session/session';

function SuperAdminView({ children, not }) {
    if ((!not && session.isSuperAdmin()) || (not && !session.isSuperAdmin())) {
        return children;
    }

    return null;
}

SuperAdminView.propTypes = {
    children: PropTypes.node.isRequired,
    not: PropTypes.bool,
};

SuperAdminView.defaultProps = {
    not: false,
};

export default SuperAdminView;
