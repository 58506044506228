import { cloneElement, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function FormDialog({ title, open, onClose, setFormValue, dialogMaxWidth, children }) {
    const [hasChanged, setChanged] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const closeDialog = useCallback(skipWarning => {
        if (hasChanged && !skipWarning) {
            setShowWarning(true);
        } else {
            setChanged(false);
            setShowWarning(false);
            onClose();
        }
    }, [hasChanged, onClose]);

    return (
        <Dialog
            open={open}
            maxWidth={dialogMaxWidth}
            onClose={() => closeDialog(false)}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            {cloneElement(children, { closeDialog, setChanged, setFormValue })}
            <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
                <DialogTitle>Close form?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have made changes, these will be lost when closing the form.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setShowWarning(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={() => closeDialog(true)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
}

FormDialog.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    setFormValue: PropTypes.func,
    dialogMaxWidth: PropTypes.string,
};

FormDialog.defaultProps = {
    setFormValue: null,
    dialogMaxWidth: 'sm',
};

export default FormDialog;
