import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Drawer, List, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
// import BuildIcon from '@mui/icons-material/Build';
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Logo from './Logo';
import createEither from './createEither';
import SidebarItem, { types } from './SidebarItem';
import { setSidebarOpenAction } from '../state';
import { useActions } from '../hooks';

const StyledDesktopDrawer = styled(Drawer)`
    position: relative;
    width: 240px;
    overflow-x: hidden;
    
    .MuiPaper-root {
        width: 240px;
        overflow-x: hidden;
        margin-top: 66px;
        background-color: transparent;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
        width: 320px;
        
        .MuiPaper-root {
            width: 320px;
        }
    }
`;
const StyledMobileDrawer = styled(Drawer)`
    width: 260px;
    overflow-x: hidden;
    
    .MuiPaper-root {
        width: 260px;
        overflow-x: hidden;
    }
`;
const StyledList = styled(List)`
    margin-top: ${({ theme }) => theme.spacing(4)};
`;
const StyledLogo = styled(Logo)`
    margin: ${({ theme }) => theme.spacing(3, 5, 1, 5)};
`;
const StyledIndicator = styled.span`
    position: absolute;
    height: 34px;
    width: 6px;
    right: 0;
    transition: ${({ theme }) => theme.transitions.create('top', { duration: 200 })};
    top: ${({ indicatorY }) => indicatorY + 2}px;
    background-color: ${({ theme }) => theme.palette.primary.main};
`;

const items = [
    { type: types.LINK, label: 'Dashboard', link: '/dashboard', Icon: DashboardIcon },
    { type: types.DIVIDER },
    { type: types.TITLE, label: 'SUPPORT' },
    {
        type: types.LINK,
        label: 'Manuals',
        link: '/manuals',
        Icon: MenuBookIcon,
        description: 'View all manuals about the Intelligent Fridges platform.',
    },
    {
        type: types.LINK,
        label: 'FAQ',
        link: '/faq',
        Icon: HelpOutlineIcon,
        description: 'Question? Find your answers in our comprehensive FAQ.',
    },
];

function SidebarItems() {
    return items.map((item, index) =>
        <SidebarItem
            key={index}
            type={item.type}
            label={item.label}
            link={item.link}
            Icon={item.Icon}
        />,
    );
}

const MaybeDesktopDrawer = createEither({
    Left: ({ children, isSidebarOpened, setSidebarOpen }) => (
        <StyledMobileDrawer
            variant="temporary"
            anchor="left"
            open={isSidebarOpened}
            onClose={() => setSidebarOpen(false)}
            ModalProps={{ keepMounted: true }}
        >
            <StyledLogo />
            {children}
        </StyledMobileDrawer>
    ),
    Right: ({ children, indicatorY }) => (
        <StyledDesktopDrawer variant="permanent">
            <StyledIndicator indicatorY={indicatorY} />
            {children}
        </StyledDesktopDrawer>
    ),
    isRight: ({ isDesktop }) => isDesktop,
});

function Sidebar() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const location = useLocation();
    const isSidebarOpened = useSelector(state => state.sidebar.isSidebarOpened);
    const setSidebarOpen = useActions(setSidebarOpenAction);

    const indicatorY = useMemo(() => {
        const itemIndex = items.findIndex(item => item.link === location.pathname);

        return items.slice(0, itemIndex).reduce((sum, item) => {
            switch (item.type) {  // Hardcoded item heights not ideal
                case types.TITLE:
                    return sum + 29;
                case types.DIVIDER:
                    return sum + 24;
                case types.LINK:
                default:
                    return sum + 38;
            }
        }, 40);
    }, [location]);

    return (
        <MaybeDesktopDrawer
            isDesktop={isDesktop}
            isSidebarOpened={isSidebarOpened}
            setSidebarOpen={setSidebarOpen}
            indicatorY={indicatorY}
        >
            <StyledList>
                <SidebarItems />
            </StyledList>
        </MaybeDesktopDrawer>
    );
}

export { items };
export default Sidebar;
