import { getNewStateOnlyOnChange } from '../../util/functions';
import HTTPRequest from '../../api/HTTPRequest';

export const initialState = {
    primaryColor: '2398b2',
    secondaryColor: 'FF5C93',
    logoLink: undefined,
    headerLogoLink: undefined,
    merchant: { name: '', urlName: '' },
    isLoadingFinished: false,
    faviconUrl: 'favicon.ico',
    hasLoadingError: false,
};

export const GET_CONFIGURATION_SUCCESS = 'configuration/GET_CONFIGURATION_DATA_SUCCESS';
export const GET_CONFIGURATION_FAIL = 'configuration/GET_CONFIGURATION_DATA_FAIL';

export function configurationReducer(state = initialState, { type, data }) {
    switch (type) {
        case GET_CONFIGURATION_SUCCESS: {
            return getNewStateOnlyOnChange(state, {
                ...state,
                ...data,
            });
        }
        case GET_CONFIGURATION_FAIL: {
            return getNewStateOnlyOnChange(state, {
                ...state,
                ...data,
            });
        }
        default:
            return state;
    }
}

export function loadConfigurationAction() {
    return (dispatch) => {
        HTTPRequest({ path: `/domainconfigurations/domainname/${window.location.hostname}` })
            .then(({ data }) => {
                dispatch({
                    type: GET_CONFIGURATION_SUCCESS,
                    data: { isLoadingFinished: true, hasLoadingError: false, ...data },
                });
            })
            .catch(() =>
                dispatch({
                    type: GET_CONFIGURATION_FAIL,
                    data: { isLoadingFinished: true, hasLoadingError: true },
                }),
            );
    };
}

