import { keycloakRef } from '../KeycloakProvider';

const keycloakSession = {
    getUserId() {
        return keycloakRef.current.subject.split(':')[2];
    },

    getFirstName() {
        return keycloakRef.current.idTokenParsed?.given_name;
    },

    getLastName() {
        return keycloakRef.current.idTokenParsed?.family_name;
    },

    getFullName() {
        return keycloakRef.current.tokenParsed?.name;
    },

    getEmail() {
        return keycloakRef.current.tokenParsed?.email;
    },

    getAccessToken() {
        return `Bearer ${keycloakRef.current.token}`;
    },

    isAuthenticated() {
        return keycloakRef.current?.authenticated;
    },

    isSuperAdmin() {
        return keycloakRef.current?.hasRealmRole('ROLE_ADMIN');
    },

    isMerchantAdmin() {
        return !this.isSuperAdmin() && keycloakRef.current?.hasRealmRole('ROLE_MERCHANT_ADMIN');
    },

    isNoAdmin() {
        const roles = keycloakRef.current.tokenParsed?.realm_access?.roles;

        return !roles || !roles.some(r => r.endsWith('_ADMIN'));
    },

    loginWithCredentials() {
        throw new Error('Not implemented for Keycloak');
    },

    loginWithToken() {
        throw new Error('Not implemented for Keycloak');
    },

    logout() {
        keycloakRef.current.logout();
    },

    updatePassword() {
        keycloakRef.current.login({ action: 'UPDATE_PASSWORD' });
    },
};

export default keycloakSession;
