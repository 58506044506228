import GothamProWoff from '../assets/fonts/GothamPro.woff';
import GothamProWoff2 from '../assets/fonts/GothamPro.woff2';
import GothamProBoldWoff from '../assets/fonts/GothamPro-Bold.woff';
import GothamProBoldWoff2 from '../assets/fonts/GothamPro-Bold.woff2';
import GothamProBoldItalicWoff from '../assets/fonts/GothamPro-BoldItalic.woff';
import GothamProBoldItalicWoff2 from '../assets/fonts/GothamPro-BoldItalic.woff2';
import GothamProItalicWoff from '../assets/fonts/GothamPro-Italic.woff';
import GothamProItalicWoff2 from '../assets/fonts/GothamPro-Italic.woff2';
import GothamProLightWoff from '../assets/fonts/GothamPro-Light.woff';
import GothamProLightWoff2 from '../assets/fonts/GothamPro-Light.woff2';
import GothamProLightItalicWoff from '../assets/fonts/GothamPro-LightItalic.woff';
import GothamProLightItalicWoff2 from '../assets/fonts/GothamPro-LightItalic.woff2';
import GothamProMediumWoff from '../assets/fonts/GothamPro-Medium.woff';
import GothamProMediumWoff2 from '../assets/fonts/GothamPro-Medium.woff2';
import GothamProMediumItalicWoff from '../assets/fonts/GothamPro-MediumItalic.woff';
import GothamProMediumItalicWoff2 from '../assets/fonts/GothamPro-MediumItalic.woff2';

// unicodeRange affects only the loading of the font into the browser, not the download size

const gothamProLight = `@font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: 
      local('GothamPro Light'),
      local('GothamPro-Light'),
      url(${GothamProLightWoff2}) format('woff2'),
      url(${GothamProLightWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;
const gothamProLightItalic = `@font-face {
    font-family: 'GothamPro';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: 
      local('GothamPro Light Italic'),
      local('GothamPro-LightItalic'),
      url(${GothamProLightItalicWoff2}) format('woff2'),
      url(${GothamProLightItalicWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;
const gothamPro = `@font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: 
      local('GothamPro'),
      local('GothamPro-Regular'),
      url(${GothamProWoff2}) format('woff2'),
      url(${GothamProWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;
const gothamProItalic = `@font-face {
    font-family: 'GothamPro';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: 
      local('GothamPro Italic'),
      local('GothamPro-Italic'),
      url(${GothamProItalicWoff2}) format('woff2'),
      url(${GothamProItalicWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;
const gothamProMedium = `@font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: 
      local('GothamPro Medium'),
      local('GothamPro-Medium'),
      url(${GothamProMediumWoff2}) format('woff2'),
      url(${GothamProMediumWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;
const gothamProMediumItalic = `@font-face {
    font-family: 'GothamPro';
    font-style: 'italic;
    font-display: 'swap;
    font-weight: 500;
    src: 
      local('GothamPro Medium Italic'),
      local('GothamPro-MediumItalic'),
      url(${GothamProMediumItalicWoff2}) format('woff2'),
      url(${GothamProMediumItalicWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;
const gothamProBold = `@font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: 
      local('GothamPro Bold'),
      local('GothamPro-Bold'),
      url(${GothamProBoldWoff2}) format('woff2'),
      url(${GothamProBoldWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;
const gothamProBoldItalic = `@font-face {
    font-family: 'GothamPro';
    font-style: italic;
    font-display: swap;
    font-weight: 600;
    src:
      local('GothamPro Bold Italic'),
      local('GothamPro-BoldItalic'),
      url(${GothamProBoldItalicWoff2}) format('woff2'),
      url(${GothamProBoldItalicWoff}) format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}`;

export {
    gothamProLight,
    gothamProLightItalic,
    gothamPro,
    gothamProItalic,
    gothamProMedium,
    gothamProMediumItalic,
    gothamProBold,
    gothamProBoldItalic,
};
