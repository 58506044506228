import { useEffect } from 'react';
import styled from '@emotion/styled';
import remove from 'lodash/remove';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, IconButton } from '@mui/material';
import { removeSnackbarAction } from '../state';
import { useActions } from '../hooks';

const StyledIconButton = styled(IconButton)`
    padding: 6px;
`;

const current = [];

// Used to show snackbar from redux action

function getAction(closeSnackbar, handleUndo) {
    return key => <>
        {
            handleUndo &&
            <Button color="inherit" size="small" onClick={handleUndo}>
                Undo
            </Button>
        }
        <StyledIconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
        >
            <ClearIcon />
        </StyledIconButton>
    </>;
}

function Notifier() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const notifications = useSelector(state => state.snackbar.notifications);
    const removeSnackbar = useActions(removeSnackbarAction);

    useEffect(() => {
        notifications.forEach(({ message, options, key }) => {
            if (current.includes(key)) return;

            const { handleUndo, ...rest } = options;

            enqueueSnackbar(message, {
                ...rest,
                key,
                action: getAction(closeSnackbar, handleUndo),
                autoHideDuration: handleUndo ? 10000 : 5000,
                onExited: (event, exitedKey) => {
                    removeSnackbar(exitedKey);
                    remove(current, c => c === exitedKey);
                },
            });

            current.push(key);
        });
    }, [notifications, enqueueSnackbar, removeSnackbar]);

    return null;
}

export default Notifier;
