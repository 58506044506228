import styled from '@emotion/styled';
import { Grid, Link, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

const Root = styled.div`
    display: flex;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
`;
const StyledLogoContainer = styled(Grid)`
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const StyledTitle = styled(Typography)`
    font-size: 3.4rem;
    letter-spacing: 26px;
    text-align: center;
    margin: ${({ theme }) => theme.spacing(0, 2)};
    color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledUnderTitle = styled(Typography)`
    font-size: 2rem;
    text-align: center;
    margin: ${({ theme }) => theme.spacing(0, 2)};
    color: ${({ theme }) => theme.palette.primary.main};
`;
const StyledEmail = styled(Typography)`
    font-size: 1.5rem;
    text-align: center;
    margin: ${({ theme }) => theme.spacing(0, 2)};
    color: ${({ theme }) => theme.palette.primary.main};
`;


function ErrorView() {

    return (
        <Root>
            <CssBaseline />
            <Grid container>
                <StyledLogoContainer item>
                    <StyledTitle>
                        ERROR
                    </StyledTitle>
                    <StyledUnderTitle>
                        Unable to connect to the server
                    </StyledUnderTitle>
                    <Link href="mailto:support@intelligentfridges.com">
                        <StyledEmail>
                            support@intelligentfridges.com
                        </StyledEmail>
                    </Link>

                </StyledLogoContainer>
            </Grid>
        </Root>
    );
}

export default ErrorView;