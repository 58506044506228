import { Button, CssBaseline, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import session from '../../auth/session/session';

const StyledContainer = styled.div`
    text-align: center;
    padding-top: 80px;
`;
const StyledH1 = styled(Typography)`
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
`;

function NoAccess() {
    const navigate = useNavigate();

    return (
        <>
            <CssBaseline />
            <Grid container spacing={3}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <StyledContainer>
                                <StyledH1 variant="h1">No Access</StyledH1>
                                <Typography>
                                    You do not have the correct access rights yet for these pages.<br />
                                    If you think you should have access, please contact
                                    support at <a
                                        href="mailto:support@intelligentfridges.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >support@intelligentfridges.com</a>.
                                </Typography>
                                <p />
                                <Button
                                    onClick={() => {
                                        session.logout()?.finally(() => navigate('/'));
                                    }}
                                >
                                    Log out
                                </Button>
                            </StyledContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default NoAccess;
