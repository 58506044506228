import { diff } from 'deep-object-diff';

function logger({ getState }) {
    return next => action => {
        console.group(action.type);

        const oldState = getState();
        const result = next(action);

        console.log('change', diff(oldState, getState()));
        console.groupEnd();

        return result;
    };
}

export default logger;
