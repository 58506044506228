export const initialState = {
    hasError: false,
};

export const SET_KEYCLOAK_ERROR = 'keycloak/SET_KEYCLOAK_ERROR';

export function keycloakReducer(state = initialState, { type }) {
    switch (type) {
        case SET_KEYCLOAK_ERROR: {
            return {
                hasError: true,
            };
        }
        default:
            return state;
    }
}

export function setKeycloakErrorAction() {
    return {
        type: SET_KEYCLOAK_ERROR,
    };
}

