import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import createEither from '../../components/createEither';
import MaybeLoading from '../../components/MaybeLoading';
import { usePageContent } from '../../hooks';
import FaqEditor from './components/FaqEditor';
import FaqContent from './components/FaqContent';

const MaybeEditing = createEither({
    Left: FaqContent,
    Right: FaqEditor,
    isRight: ({ isEditing }) => isEditing,
});

function Faq() {
    const [markdown, isLoading, setMarkdown] = usePageContent('/support/forward/pagecontent/faq');
    const [isEditing, setEditing] = useState(false);
    const location = useLocation();

    return (
        <>
            <PageTitle title="Frequently Asked Questions" />
            <MaybeLoading isLoading={isLoading}>
                <MaybeEditing
                    markdown={markdown}
                    setMarkdown={setMarkdown}
                    isEditing={isEditing}
                    setEditing={setEditing}
                    locationHash={location.hash}
                />
            </MaybeLoading>
        </>
    );
}

export default Faq;
