import { GET, POST } from './HTTPRequestMethod';
import { GRAPHQL_ORIGIN, SERVER_ORIGIN } from '../config';

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

function processResponse(response) {
    const contentType = response.headers.get('content-type');

    if (!contentType) {
        return Promise.resolve();
    }

    if (contentType.toLowerCase().indexOf('json') >= 0) {
        return response.json().catch(() => Promise.resolve());  // TODO probably improve the .catch
    }

    if (contentType.toLowerCase().indexOf('text') >= 0) {
        return response.text();
    }
}

function checkStatus(response) {
    const { status } = response;

    if (status >= 200 && status < 300) {
        return processResponse(response);
    }

    return processResponse(response).then(result => {
        console.error(result);

        return Promise.reject(result);
    });
}

function HTTPRequest({
    url,
    path,
    origin,
    body,
    query,
    variables,
    params,
    headers,
    method,
    accessToken,
}) {
    const theOrigin = origin || (query ? GRAPHQL_ORIGIN : SERVER_ORIGIN);
    const theUrl = url || buildUrl(path, theOrigin, params);
    const theBody = body || (query ? { query, variables } : null);

    const init = {
        method: method || (theBody ? POST : GET),
        body: theBody && theBody === Object(theBody) && !(theBody instanceof FormData)
            ? JSON.stringify(theBody)
            : theBody,
        headers: accessToken
            ? { ...defaultHeaders, Authorization: accessToken, ...headers }
            : { ...defaultHeaders, ...headers },
    };

    if (theBody instanceof FormData) {
        delete init.headers['Content-Type'];
    }

    if (init.method === GET) {
        console.log(`fetching ${theUrl}`);
    } else {
        console.log(`posting ${theUrl} ${init.body}`);
    }

    return fetch(theUrl, init)
        .then(checkStatus);
}

export function buildUrl(path, origin, params) {
    if (params) {
        const queryParams = Object.keys(params).map(key => `${key}=${params[key]}`);

        return `${origin}${path || ''}?${queryParams.join('&')}`;
    }

    return `${origin}${path || ''}`;
}

export default HTTPRequest;
