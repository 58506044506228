import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { useSelector } from 'react-redux';
import defaultTheme from './themes/default';
import Notifier from './components/Notifier';
import SnackbarProvider from './components/SnackbarProvider';
import StartupDataLoader from './components/StartupDataLoader';
import { setBrowserTitle, setFavicon } from './util/functions';
import { useDeepEqualMemo } from './hooks/useDeepEqualMemo';
import ConfigurationLoader from './components/ConfigurationLoader';
import KeycloakProvider from './auth/KeycloakProvider';
import { useActions } from './hooks';
import { setKeycloakErrorAction } from './state';
import { KEYCLOAK_AUTH_SERVER_URL, KEYCLOAK_REALM } from './config';
import AppRoutes from './views/AppRoutes';
import ScrollHandler from './components/ScrollHandler';

function App() {
    const config = useDeepEqualMemo(useSelector(state => state.configuration));
    const [theme, setTheme] = useState(createTheme(defaultTheme(config)));
    const setKeycloakError = useActions(setKeycloakErrorAction);

    useEffect(() => {
        const newTheme = createTheme(defaultTheme(config));

        setTheme(newTheme);
        setFavicon(config.faviconUrl);
        setBrowserTitle(`Support - ${config.merchant.name}`);

        if (process.env.NODE_ENV === 'development') {
            window.theme = theme;
        }
    }, [config]);

    return (
        <KeycloakProvider
            realm={KEYCLOAK_REALM}
            authServerUrl={KEYCLOAK_AUTH_SERVER_URL}
            clientId="fridge-support-react"
            domainConfiguration={config}
            handleError={setKeycloakError}
            isAppInitialized={config.isLoadingFinished && !config.hasLoadingError}
        >
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider>
                            <ConfigurationLoader>
                                <StartupDataLoader>
                                    <Notifier />
                                    <BrowserRouter>
                                        <ScrollHandler>
                                            <AppRoutes />
                                        </ScrollHandler>
                                    </BrowserRouter>
                                </StartupDataLoader>
                            </ConfigurationLoader>
                        </SnackbarProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StyledEngineProvider>
        </KeycloakProvider>
    );
}

export default App;
