import { isEmpty } from 'lodash';

const {
    REACT_APP_SERVER_ORIGIN: SERVER_ORIGIN,
    REACT_APP_KEYCLOAK_REALM: KEYCLOAK_REALM,
    REACT_APP_KEYCLOAK_AUTH_SERVER_URL: KEYCLOAK_AUTH_SERVER_URL,
} = process.env;

const SESSION_DURATION = '30D';
const GRAPHQL_ORIGIN = SERVER_ORIGIN.replace('/api', '/graphql');
const KEYCLOAK_DISABLED = process.env.NODE_ENV === 'development' && isEmpty(KEYCLOAK_AUTH_SERVER_URL);

export {
    SERVER_ORIGIN,
    GRAPHQL_ORIGIN,
    KEYCLOAK_REALM,
    KEYCLOAK_AUTH_SERVER_URL,
    KEYCLOAK_DISABLED,
    SESSION_DURATION,
};
