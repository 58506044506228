import styled from '@emotion/styled';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SuperAdminView from '../../../components/SuperAdminView';
import FaqQuestions from './FaqQuestions';

const StyledButtonContainer = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

function FaqContent({ markdown, setEditing, locationHash }) {
    return (
        <>
            <SuperAdminView>
                <StyledButtonContainer>
                    <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => setEditing(true)}
                    >
                        Edit
                    </Button>
                </StyledButtonContainer>
            </SuperAdminView>
            <div>
                <FaqQuestions
                    markdown={markdown}
                    locationHash={locationHash}
                />
            </div>
        </>
    );
}

export default FaqContent;
