import styled from '@emotion/styled';
import { SnackbarProvider as NotistackProvider } from 'notistack';

const StyledNotistackProvider = styled(NotistackProvider)`
    .MuiSnackbarContent-message, .MuiSnackbarContent-action {
        color: #fff;
    }
`;

function SnackbarProvider({ children }) {
    return (
        <StyledNotistackProvider maxSnack={3} preventDuplicate={false}>
            {children}
        </StyledNotistackProvider>
    );
}

export default SnackbarProvider;
