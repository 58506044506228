import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { KEYCLOAK_DISABLED } from '../config';

const themeConfiguration = { current: {} };
const keycloakRef = { current: {} };

const initOptions = {
    enableLogging: true,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    adapter: {
        login: options => {
            window.location.href = addParamsToUrl(keycloakRef.current.createLoginUrl(options));
        },
        logout: options => {
            window.location.href = addParamsToUrl(keycloakRef.current.createLogoutUrl(options));
        },
        redirectUri: options => {
            if (options && options.redirectUri) { return options.redirectUri; }
            if (keycloakRef.current.redirectUri) { return keycloakRef.current.redirectUri; }
            return window.location.href;
        },
    },
};

function addParamsToUrl(url) {
    const result = new URL(url);

    const queryParams = {
        ui_locales: 'en',
        merchantUrlName: themeConfiguration.current.merchant?.urlName,
        merchantName: themeConfiguration.current.merchant?.name,
        primaryColor: themeConfiguration.current.primaryColor,
        secondaryColor: themeConfiguration.current.secondaryColor,
        faviconUrl: themeConfiguration.current.faviconUrl,
        logoLink: themeConfiguration.current.logoLink,
        domain: window.location.hostname,
    };

    Object.keys(queryParams).forEach(name => {
        queryParams[name] && result.searchParams.append(name, queryParams[name]);
    });

    return result.toString();
}

function KeycloakProvider({
    realm,
    authServerUrl,
    clientId,
    domainConfiguration,
    isAppInitialized,
    handleError,
    children,
}) {
    keycloakRef.current = useMemo(() => !KEYCLOAK_DISABLED && new Keycloak({
        realm,
        clientId,
        url: authServerUrl,
    }), [realm, clientId, authServerUrl]);

    const handleKeycloakEvent = useCallback((event, error) => {
        if (process.env.NODE_ENV === 'development') {
            console.log('onKeycloakEvent', event, error);
            console.log(keycloakRef.current);
        }

        if (event === 'onReady') {
            keycloakRef.current.initialized = true;

            if (!keycloakRef.current.authenticated) {
                if (themeConfiguration.current.isLoadingFinished === undefined
                    || themeConfiguration.current.isLoadingFinished) {
                    keycloakRef.current.login();
                } else {
                    console.warn('Keycloak ready before theme loading finished');
                }
            }
        }

        if (event === 'onInitError') {
            console.warn(error?.error);
            handleError(error?.error);
        }
    }, [handleError]);

    useEffect(() => {
        themeConfiguration.current = domainConfiguration;  // For keycloak config
    }, [domainConfiguration]);

    useEffect(() => {
        // Sometimes login is called in the onReady event before config has been loaded, so do it here
        if (isAppInitialized
            && keycloakRef.current
            && keycloakRef.current.initialized
            && !keycloakRef.current.authenticated) {
            keycloakRef.current.login();
        }
    }, [isAppInitialized]);

    if (KEYCLOAK_DISABLED) {
        return children;
    }

    return (
        <ReactKeycloakProvider
            authClient={keycloakRef.current}
            initOptions={initOptions}
            onEvent={handleKeycloakEvent}
            onTokens={tokens => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('onKeycloakTokens', tokens);
                }
            }}
        >
            {children}
        </ReactKeycloakProvider>
    );
}

KeycloakProvider.propTypes = {
    realm: PropTypes.string.isRequired,
    authServerUrl: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isAppInitialized: PropTypes.bool,
    domainConfiguration: PropTypes.object,
    handleError: PropTypes.func,
};

KeycloakProvider.defaultProps = {
    isAppInitialized: true,
    domainConfiguration: {},
    handleError: () => {},
};

export { keycloakRef };
export default KeycloakProvider;
