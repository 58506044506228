import tinycolor from 'tinycolor2';
import {
    gothamPro,
    gothamProBold,
    gothamProBoldItalic,
    gothamProItalic,
    gothamProLight,
    gothamProLightItalic,
    gothamProMedium,
    gothamProMediumItalic,
} from './fonts';
import { mergeTransparentColorWithWhite } from '../util/functions';

function defaultTheme({ primaryColor, secondaryColor }) {
    const warning = '#FFC260';
    const success = '#43a047';
    const error = '#f44336';
    const info = '#9013FE';

    const primary = `#${primaryColor}`;
    const secondary = `#${secondaryColor}`;
    const background = mergeTransparentColorWithWhite(`${primary}0b`);

    const lightenRate = 7.5;
    const darkenRate = 15;

    return (
        {
            palette: {
                primary: {
                    contrastText: '#fff',
                    main: primary,
                    light: tinycolor(primary)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(primary)
                        .darken(darkenRate)
                        .toHexString(),
                },
                secondary: {
                    main: secondary,
                    light: tinycolor(secondary)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(secondary)
                        .darken(darkenRate)
                        .toHexString(),
                    contrastText: '#FFFFFF',
                },
                warning: {
                    main: warning,
                    light: tinycolor(warning)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(warning)
                        .darken(darkenRate)
                        .toHexString(),
                },
                success: {
                    main: success,
                    light: tinycolor(success)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(success)
                        .darken(darkenRate)
                        .toHexString(),
                },
                error: {
                    main: error,
                    light: tinycolor(error)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(error)
                        .darken(darkenRate)
                        .toHexString(),
                },
                info: {
                    main: info,
                    light: tinycolor(info)
                        .lighten(lightenRate)
                        .toHexString(),
                    dark: tinycolor(info)
                        .darken(darkenRate)
                        .toHexString(),
                },
                text: {
                    primary: '#4A4A4A',
                    secondary: '#7b7b7b',
                    hint: '#ababab',
                },
                background: {
                    default: background,
                    light: background,
                    disabled: '#eeeeee',
                },
            },
            customShadows: {
                widget:
                    '0px 3px 11px 0px #10101012, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
                widgetDark:
                    '0px 3px 20px 0px #42424261, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
                widgetWide:
                    '0px 12px 33px 0px #10101012, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
            },
            breakpoints: {  // Old breakpoints from before MUI v5
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                },
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                        ${gothamProLight}
                        ${gothamProLight}
                        ${gothamProLightItalic}
                        ${gothamPro}
                        ${gothamProItalic}
                        ${gothamProMedium}
                        ${gothamProMediumItalic}
                        ${gothamProBold}
                        ${gothamProBoldItalic}
                    `,
                },
                MuiPaper: {
                    styleOverrides: {
                        elevation1: {
                            boxShadow: '0px 3px 11px 0px #10101012, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
                        },
                    },
                },
            },
            typography: {
                fontFamily: 'GothamPro, Roboto, Helvetica, Arial, sans-serif',
                h1: {
                    fontSize: '2.5rem',
                    marginBottom: '4px',
                    '@media (min-width:600px)': {
                        fontSize: '3.4rem',
                    },
                },
                h2: {
                    fontSize: '1.5rem',
                    '@media (min-width:600px)': {
                        fontSize: '1.9rem',
                    },
                },
                h3: {
                    fontSize: '1.4rem',
                    '@media (min-width:600px)': {
                        fontSize: '1.58rem',
                    },
                },
                h4: {
                    fontSize: '1.12rem',
                    '@media (min-width:600px)': {
                        fontSize: '1.17rem',
                    },
                },
                h5: {
                    fontSize: '1.285rem',
                },
                h6: {
                    fontSize: '1.142rem',
                },
                body2: {
                    fontSize: '0.875rem',
                    '@media (min-width:600px)': {
                        fontSize: '0.92rem',
                    },
                },
            },
        }
    );
}


export default defaultTheme;
