import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useActions } from './useActions';
import { requestAction } from '../state';

// Caches page content after first load so we don't have to show loader each time
export function usePageContent(path) {
    const [data, setData] = useState(localStorage.getItem(path) || '');
    const [isLoading, setLoading] = useState(isEmpty(data));
    const request = useActions(requestAction);

    const updateData = useCallback(content => {
        setData(content);
        localStorage.setItem(path, content);
    }, [path]);

    useEffect(() => {
        request({ path, errorMessage: 'Failed to load page content' })
            .then(({ content }) => updateData(content || ''))
            .catch(() => setData(''))
            .finally(() => setLoading(false));
    }, [request, updateData]);

    return [data, isLoading, updateData];
}
