import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ButtonBase, Chip, FormHelperText, IconButton, Tooltip, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { useDropzone } from 'react-dropzone';
import { get, isEmpty, merge } from 'lodash';
import createEither from '../createEither';
import { composeValidators } from '../../util/formValidators';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const StyledLabel = styled(({ isActive, hasError, ...rest }) => <Typography {...rest} />)`
    font-size: 0.9rem;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
    color: ${({ isActive, hasError, theme }) => hasError ? theme.palette.error.main : isActive ? theme.palette.primary.main : theme.palette.text.secondary};
`;
const StyledLabelContainer = styled.div`
    display: flex;
`;
const StyledHelpIcon = styled(HelpIcon)`
    margin-left: ${({ theme }) => theme.spacing(1)};
`;
const StyledContent = styled.div`
    display: flex;
    height: 100%;
`;
const StyledDropZoneRoot = styled.div`
    width: 100%;
    height: 164px;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    background-color: #4A4A4A1A;
    border: 2px dashed ${({ theme, isActive, hasError }) => hasError ? theme.palette.error.main : isActive ? theme.palette.primary.main : theme.palette.text.hint};
    color: ${({ theme }) => theme.palette.text.secondary};

    :focus {
        border-color: ${({ theme, hasError }) => hasError ? theme.palette.error.main : theme.palette.primary.main};
    }
`;
const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
const StyledFileContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    background-color: #4A4A4A1A;
    border-radius: 4px;
    border: 2px dashed ${({ theme, isActive, hasError }) => hasError ? theme.palette.error.main : isActive ? theme.palette.primary.main : theme.palette.text.hint};
`;
const StyledFileWrapper = styled.div`
    display: flex;
    position: relative;
    min-height: 160px;
    align-items: center;
`;
const StyledChip = styled(Chip)`
    max-width: 360px;
    max-height: 160px;
    height: auto;
    overflow-wrap: anywhere;
    border-radius: 8px;
    border-color: ${({ theme }) => theme.palette.text.hint};
    background-color: white;
`;
const StyledChipLabel = styled(Typography)`
    max-height: 160px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.text.main};
    white-space: normal;
    border-radius: 16px;
    cursor: text;
    user-select: text;
`;
const StyledFileError = styled.div`
    display: flex;
    width: 100%;
    overflow: auto;
    align-items: center;
    gap: 8px;
`;
const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
    margin-right: ${({ theme }) => theme.spacing(1)};
`;
const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
`;
const StyledFormHelperText = styled(FormHelperText)`
    position: absolute;
    bottom: -24px;
    left: 14px;
    color: ${({ theme }) => theme.palette.error.main};
`;

function DropZone({
    name,
    setFocused,
    useFormMethods,
    isDragActive,
    getRootProps,
    getInputProps,
    validators,
}) {
    const { watch, register, formState: { errors } } = useFormMethods;

    const validatorObj = composeValidators(watch, ...validators);

    useEffect(() => {
        setFocused(false);
    }, []);

    return (
        <ButtonBase focusRipple component="div" tabIndex={-1}>
            <StyledDropZoneRoot
                {...getRootProps()}
                isActive={isDragActive}
                hasError={get(errors, name)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            >
                <input {...register(name, validatorObj)} {...getInputProps()} />
                <StyledContent>
                    <StyledTextContainer>
                        <Typography paragraph variant="h5" component="p">
                            Drag and drop file here, or click
                        </Typography>
                        <CloudUploadIcon fontSize="large" />
                    </StyledTextContainer>
                </StyledContent>
            </StyledDropZoneRoot>
            {
                get(errors, name) &&
                <StyledFormHelperText>{get(errors, name)?.message}</StyledFormHelperText>
            }
        </ButtonBase>
    );
}

function FilePreview({ name, file, useFormMethods }) {
    const { setValue, errors, clearErrors } = useFormMethods;

    return (
        <StyledFileContainer>
            <StyledFileWrapper>
                {
                    get(errors, name) ?
                        <StyledFileError>
                            <StyledIconButton
                                size="small"
                                onChange={() => {
                                    setValue(name, null);
                                }}
                            >
                                <ClearIcon />
                            </StyledIconButton>
                            <StyledErrorOutlineIcon 
                                color="error" 
                                fontSize="large" 
                            />
                            <Typography 
                                variant="caption"
                            >
                                File not found
                            </Typography>
                        </StyledFileError>
                        :
                        <StyledChip
                            label={
                                <StyledChipLabel>
                                    {file.name}
                                </StyledChipLabel>
                            }
                            onDelete={() => {
                                clearErrors(name);
                                setValue(name, null);
                            }}
                            variant="outlined"
                            avatar={<InsertDriveFileIcon />}
                        />
                }
            </StyledFileWrapper>
        </StyledFileContainer>
    );
}

const DropZoneOrPreview = createEither({
    Left: DropZone,
    Right: FilePreview,
    isRight: ({ file }) => file,
});

function FormDialogFileUpload({
    name,
    label,
    maxSize,
    helpText,
    allowedMimeTypes,
    validators,
    useFormMethods,
}) {
    const [isFocused, setFocused] = useState(false);

    const { getValues, setValue, setError, clearErrors, formState: { errors } } = useFormMethods;

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        const error = rejectedFiles[0]?.errors[0];

        if (error) {
            const errorMessage = error.code === 'file-too-large'
                ? `File can't be larger than ${Math.round(maxSize / 100000) / 10} MB`
                : error.code === 'file-invalid-type'
                    ? 'File type is invalid'
                    : error.message;

            setError(name, {
                type: 'custom',
                message: errorMessage,
            });
        } else if (!isEmpty(acceptedFiles)) {
            const droppedData = acceptedFiles.length === 1 ? acceptedFiles[0] : acceptedFiles;

            clearErrors(name);
            setValue(name, droppedData);
            setFocused(true);
        }
    }, []);

    const dropzoneProps = useDropzone({
        onDrop,
        maxSize,
        multiple: false, // No support for multiple files yet
        // [{ 'a': 'b'}, {'c': 'd'}]  ->  { 'a': 'b', 'c': 'd'}
        // https://react-dropzone.org/#!/Accepting%20specific%20file%20types
        accept: allowedMimeTypes.reduce((acc, cur) => (merge(acc, cur)), {}),
    });

    return (
        <StyledContainer>
            <StyledLabelContainer>
                <StyledLabel
                    isActive={dropzoneProps.isDragActive || isFocused}
                    hasError={get(errors, name)}
                >
                    {label}
                </StyledLabel>
                {
                    helpText &&
                    <Tooltip arrow title={helpText}>
                        <StyledHelpIcon color="primary" fontSize="small" />
                    </Tooltip>
                }
            </StyledLabelContainer>
            <DropZoneOrPreview
                validators={validators}
                name={name}
                file={getValues(name)}
                setFocused={setFocused}
                maxSize={maxSize}
                errorMessage={get(errors, name)}
                useFormMethods={useFormMethods}
                {...dropzoneProps}
            />
        </StyledContainer>
    );
}

FormDialogFileUpload.propTypes = {
    useFormMethods: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    maxSize: PropTypes.number,
    helpText: PropTypes.string,
    allowedMimeTypes: PropTypes.arrayOf(PropTypes.object),
};

FormDialogFileUpload.defaultProps = {
    label: null,
    maxSize: 20000000, // 20Mb
    helpText: null,
    allowedMimeTypes: [{ 'application/pdf': [] }],
};

export default FormDialogFileUpload;
