export const initialState = {
    isSidebarOpened: false,
};

export const SET_SIDEBAR_OPEN = 'sidebar/SET_SIDEBAR_OPEN';

export function sidebarReducer(state = initialState, { type, open }) {
    if (type === SET_SIDEBAR_OPEN) {
        return {
            ...state,
            isSidebarOpened: open,
        };
    }

    return state;
}

export function setSidebarOpenAction(open) {
    return {
        open,
        type: SET_SIDEBAR_OPEN,
    };
}
