import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import KeyIcon from '@mui/icons-material/Key';
import session from '../auth/session/session';

const StyledMenu = styled(Menu)`
    .MuiMenu-paper {
        min-width: 265px;
        margin-top: ${({ theme }) => theme.spacing(7)};
        margin-right: ${({ theme }) => theme.spacing(2)};
        padding: ${({ theme }) => theme.spacing(1, 0)};
    }

    .MuiMenu-list {
        padding-top: 4px;
        padding-bottom: 4px;
    }
`;
const StyledTitle = styled(Typography)`
    pointer-events: none;
    margin: ${({ theme }) => theme.spacing(0, 2)};

    &:focus {
        outline: initial;
    }
`;
const StyledSubtitle = styled(Typography)`
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    color: ${({ theme }) => theme.palette.primary.main};
    margin: ${({ theme }) => theme.spacing(0, 2, 1.5, 2)};
`;
const StyledMenuItem = styled(MenuItem)`
    color: ${({ theme }) => theme.palette.text.secondary}CC;

    &:hover, &:focus {
        color: ${({ theme }) => theme.palette.primary.main};

        .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }
`;
const IconWrapper = styled.div`
    display: flex;
    color: ${({ theme }) => theme.palette.text.hint};
`;
const StyledMenuItemText = styled.span`
    margin-left: ${({ theme }) => theme.spacing(1.5)};
`;

function ProfileMenu({ className }) {
    const [menu, setMenu] = useState(null);

    const navigate = useNavigate();

    return (
        <div className={className}>
            <IconButton color="inherit" onClick={e => setMenu(e.currentTarget)} size="large">
                <PersonIcon />
            </IconButton>
            <StyledMenu
                disableAutoFocusItem
                open={!!menu}
                anchorEl={menu}
                onClose={() => setMenu(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <StyledTitle variant="h5">
                    {session.getFullName() ?? '?'}
                </StyledTitle>
                <StyledSubtitle variant="subtitle2">
                    {session.isSuperAdmin() ? 'Super admin' : 'Merchant admin'}
                </StyledSubtitle>
                <StyledMenuItem onClick={() => session.updatePassword()}>
                    <IconWrapper>
                        <KeyIcon />
                    </IconWrapper>
                    <StyledMenuItemText>
                        Update password
                    </StyledMenuItemText>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={() => {
                        session.logout()?.finally(() => navigate('/'));
                    }}
                >
                    <IconWrapper>
                        <PowerIcon />
                    </IconWrapper>
                    <StyledMenuItemText>
                        Log out
                    </StyledMenuItemText>
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}

export default ProfileMenu;
