import { merge } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import isJSON from 'validator/lib/isJSON';
import isHexColor from 'validator/lib/isHexColor';

// For use in combination with react-hook-form, can replace the other formValidators eventually

function getRegisterInput(register, watch, onRef) {
    return (name, ...list) => {
        const { ref, ...rest } = register(name, composeValidators(watch, ...list));

        return { [onRef ? 'ref' : 'inputRef']: ref, ...rest };
    };
}

function composeValidators(watch, ...list) {
    const result = {};
    const objectList = list.map(item => watch && typeof item === 'function' ? item(watch) : item);

    merge(result, ...objectList);

    return result;
}

function required(message) {
    return { required: message || 'Required' };
}

function even(message) {
    return {
        validate: {
            even: value => value % 2 !== 0
                ? message || 'This value needs to be even'
                : undefined,
        },
    };
}
function email(message) {
    return {
        validate: {
            email: value => value && value.length >= 0 && !isEmail(value)
                ? message || 'Not an email address'
                : undefined,
        },
    };
}

function url(message) {
    return {
        validate: {
            url: value => value && value.length >= 0 && !isURL(value)
                ? message || 'Not an URL'
                : undefined,
        },
    };
}

function color(message) {
    return {
        validate: {
            color: value => value && value.length >= 0 && !isHexColor(value)
                ? message || 'Not a valid color code'
                : undefined,
        },
    };
}

function json(message) {
    return {
        validate: {
            json: value => value && value.length >= 0 && !isJSON(value)
                ? message || 'Invalid JSON format'
                : undefined,
        },
    };
}

function minLength(minValue, message) {
    return {
        minLength: {
            value: minValue,
            message: message || `Minimal length of ${minValue} required`,
        },
    };
}

function maxLength(maxValue, message) {
    return {
        maxLength: {
            value: maxValue,
            message: message || `Exceeds maximum length of ${maxValue}`,
        },
    };
}

function min(minValue, message) {
    return {
        validate: {
            min: value => parseFloat(value) < minValue
                ? message || `Must be ${minValue} or greater`
                : undefined,
        },
    };
}

function max(maxValue, message) {
    return {
        validate: {
            max: value => parseFloat(value) > maxValue
                ? message || `Must be ${maxValue} or less`
                : undefined,
        },
    };
}


function equals(field, message) {
    return watch => ({
        validate: {
            equals: value => value !== watch(field)
                ? message || `Must be equal to ${field}`
                : undefined,
        },
    });
}

function either(field, message) {
    return watch => ({
        validate: {
            either: value => (
                (value && watch(field)) || (!value && !watch(field))
                    ? message || `Either this field or ${field} must have a value`
                    : undefined
            ),
        },
    });
}

function pattern(value, message) {
    return {
        pattern: {
            value,
            message: message || `Does not match pattern ${value}`,
        },
    };
}

export {
    getRegisterInput,
    composeValidators,
    required,
    email,
    url,
    color,
    json,
    minLength,
    maxLength,
    min,
    max,
    equals,
    either,
    pattern,
    even,
};
