import { Provider } from 'react-redux';
import store from './store';
import App from './App';

if (process.env.NODE_ENV === 'development') {
    window.store = store;
}

function AppWrapper() {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
}

export default AppWrapper;
