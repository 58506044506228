import { isFunction } from 'lodash';
import HTTPRequest from '../../api/HTTPRequest';
import { enqueueSnackbarAction } from '../ui/snackbar';
import session from '../../auth/session/session';

export function requestAction({
    url,
    path,
    origin,
    body,
    query,
    variables,
    params,
    headers,
    successMessage,
    errorMessage,
}) {
    return dispatch => {
        const accessToken = session.getAccessToken();

        return new Promise((resolve, reject) => {
            HTTPRequest({ url, path, origin, body, query, variables, params, headers, accessToken })
                .then(response => {
                    let result = response;

                    if (query && !path) {
                        if (!response) {
                            throw new Error('Unknown GraphQL error occurred');
                        }

                        if (response.errors) {
                            throw response.errors;
                        }

                        if (response.data) {
                            const keys = Object.keys(response.data);

                            if (keys.length === 1) {
                                result = response.data[Object.keys(response.data)[0]];
                            } else {
                                result = response.data;
                            }
                        }
                    }

                    if (successMessage) {
                        dispatch(enqueueSnackbarAction(successMessage, { variant: 'success' }));
                    }

                    return resolve(result);
                })
                .catch(error => {
                    if (error?.data?.includes('token incorrect')) {
                        dispatch(enqueueSnackbarAction('Session expired', { variant: 'error' }));
                    } else if (errorMessage) {
                        const message = isFunction(errorMessage)
                            ? errorMessage(error)
                            : errorMessage;

                        dispatch(enqueueSnackbarAction(message, { variant: 'error' }));
                    }

                    if (query && !path) {
                        console.error(error);
                    }

                    return reject(error);
                });
        });
    };
}
