import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline } from '@mui/material';
import ErrorView from './ErrorView';
import { useActions } from '../hooks';
import { loadConfigurationAction } from '../state';
import CenteredLoader from './CenteredLoader';

// This is seperate from StartupDataLoader, because this NEEDS to happen before keycloak initialises
function ConfigurationLoader({ children }) {
    const { isLoadingFinished, hasLoadingError } = useSelector(state => state.configuration);
    const keycloakError = useSelector(state => state.keycloak.hasError);
    const loadConfiguration = useActions(loadConfigurationAction);

    useEffect(() => {
        loadConfiguration();
    }, []);

    if (hasLoadingError || keycloakError) {
        return <ErrorView />;
    }

    if (!isLoadingFinished) {  // We do see a weird color transition here on non-default subdomains, but I don't know a better alternative
        return <>
            <CssBaseline />
            <CenteredLoader absolute />
        </>;
    }

    return children;
}

export default ConfigurationLoader;
