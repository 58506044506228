import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const StyledTitle = styled(Typography)`
    margin-top: ${({ theme }) => theme.spacing(3)};
    color: ${({ theme }) => theme.palette.text.hint};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        margin-top: ${({ theme }) => theme.spacing(5)};
    }
`;
const StyledSubtitle = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.hint};
`;
const StyledSpacer = styled.div`
    width: 100%;
    height: ${({ theme }) => theme.spacing(6)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        height: ${({ theme }) => theme.spacing(8)};
    }
`;

function PageTitle({ title, subtitle }) {
    return (
        <>
            <StyledTitle variant="h1">
                {title}
            </StyledTitle>
            {
                subtitle &&
                <StyledSubtitle variant="h2">
                    {subtitle}
                </StyledSubtitle>
            }
            <StyledSpacer />
        </>
    );
}

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

PageTitle.defaultProps = {
    subtitle: null,
};

export default PageTitle;
