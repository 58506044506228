import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import {
    Autocomplete,
    FormHelperText,
    InputAdornment,
    DialogContent,
    TextField,
    Typography,
} from '@mui/material';
import isSemVer from 'validator/es/lib/isSemVer';
import { useActions } from '../../../hooks';
import { requestAction } from '../../../state';
import FormDialogButtons from '../../../components/formdialog/FormDialogButtons';
import FormDialogFileUpload from '../../../components/formdialog/FormDialogFileUpload';
import { required } from '../../../util/formValidators';

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 24px;
    gap: 28px;
`;
const StyledOption = styled(Typography)`
    font-size: 14px;
`;
const StyledTextField = styled(TextField)`
    width: 50%;
`;
const VersionInputContainer = styled.div`
    margin-bottom: 4px;
`;
const StyledAutocomplete = styled(Autocomplete)`
    width: 400px;
`;
const StyledFormHelperText = styled(({ isError, ...rest }) => <FormHelperText {...rest} />)`
    margin-top: 4px;
    margin-bottom: -24px;
    color: ${({ isError, theme }) => isError ? theme.palette.error.main : theme.palette.text.secondary};
`;

function ManualVersionInput({ useFormMethods }) {
    const {
        register,
        formState: { errors },
        getValues,
        setValue,
        clearErrors,
    } = useFormMethods;

    const selectedManualVersion = getValues('selectedManual')?.version;

    return (
        <VersionInputContainer>
            <StyledTextField
                {...register('manualVersion', {
                    required: true,
                    validate: {
                        isSemVer: v => !isSemVer(v)
                            ? 'Incorrect format' : null,
                        isUnique: v => v === selectedManualVersion
                            ? 'Can\'t choose current version' : null,
                    },
                })}
                required
                onChange={(e) => {
                    clearErrors('manualVersion');
                    setValue('manualVersion', e.target.value);
                }}
                label="New version"
                name="manualVersion"
                variant="outlined"
                error={!!errors.manualVersion}
                autoComplete="off"
                size="small"
                InputProps={{
                    startAdornment: <InputAdornment position="start"
                        sx={{ paddingBottom: 0.2 }}>v</InputAdornment>,
                }}
            />
            {
                // Only show error message when message is present,
                // even though it might be in error state
                errors.manualVersion?.message
                    ? <StyledFormHelperText isError>
                        {errors.manualVersion.message}
                    </StyledFormHelperText>
                    : <StyledFormHelperText>
                        Current version:
                        {
                            selectedManualVersion ? <b> v{selectedManualVersion}</b> : <b> -</b>
                        }
                    </StyledFormHelperText>
            }
        </VersionInputContainer>
    );
}

function UpdateManualForm({ manuals, updateManuals, onClose }) {
    const [isLoading, setIsLoading] = useState(false);

    const useFormMethods = useForm();
    const { handleSubmit, formState: { errors }, setValue, clearErrors, register } = useFormMethods;

    const request = useActions(requestAction);

    const submitHandler = ({ selectedManual, manualVersion, uploadedPdf }) => {
        if (Object.keys(errors).length === 0) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('file', uploadedPdf);
            formData.append('name', uploadedPdf.name);

            // Generate link to pdf
            request({
                path: '/storage/action-upload',
                body: formData,
                errorMessage: 'Failed to update manual',
            })
                .then(({ data }) => {
                    if (data) {
                        // Update with newly generated link and new version number
                        return request({
                            path: '/support/forward/files/versioned/action-add',
                            body: {
                                name: selectedManual.name,
                                link: data,
                                version: manualVersion,
                            },
                            errorMessage: 'Failed to update manual',
                            successMessage: `Successfully updated ${selectedManual.title}`,
                        });
                    }
                })
                .then(({ data }) => data && updateManuals([data]))
                .finally(() => {
                    setIsLoading(false);
                    onClose();
                });
        }
    };

    return (
        <form
            noValidate
            onSubmit={handleSubmit(submitHandler)}
        >
            <StyledDialogContent>
                <StyledAutocomplete
                    {...register('selectedManual', { required: true })}
                    disableClearable
                    size="small"
                    options={manuals}
                    getOptionLabel={e => e.title}
                    isOptionEqualToValue={(o, v) => o.title === v.title}
                    onChange={(e, v) => {
                        clearErrors('selectedManual');
                        setValue('selectedManual', v);
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOption>{option.title}</StyledOption>
                        </li>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            required
                            error={!!errors.selectedManual}
                            label="Manual to update"
                            variant="outlined"
                        />
                    )}
                />
                <ManualVersionInput
                    useFormMethods={useFormMethods}
                />
                <FormDialogFileUpload
                    name="uploadedPdf"
                    label="Manual file *"
                    allowedMimeTypes={[{ 'application/pdf': [] }]}
                    validators={[required()]}
                    useFormMethods={useFormMethods}
                />
            </StyledDialogContent>
            <FormDialogButtons
                closeDialog={onClose}
                isLoading={isLoading}
                disabled={
                    Object.keys(errors).length !== 0 // Check if errors are present
                }
            />
        </form>
    );
}

export default UpdateManualForm;

UpdateManualForm.propTypes = {
    updateManuals: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    manuals: PropTypes.array,
};

UpdateManualForm.defaultProps = {
    manuals: [],
};
