import { isEqual } from 'lodash';
import { useRef } from 'react';

export function useDeepEqualMemo(value) {
    const ref = useRef(undefined);

    if (!isEqual(ref.current, value)) {
        ref.current = value;
    }

    return ref.current;
}
