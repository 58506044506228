import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { items } from '../../components/Sidebar';
import PageCard from '../../components/PageCard';
import { types } from '../../components/SidebarItem';

function Dashboard() {
    const merchantName = useSelector(state => state.configuration.merchant.name);
    const location = useLocation();

    const pages = useMemo(() => (
        items.filter(item => item.type === types.LINK && item.link !== location.pathname)
    ), []);

    return (
        <>
            <PageTitle
                title={`${merchantName} Support`}
                subtitle="Browse support articles"
            />
            <Grid container spacing={4}>
                {
                    pages.map((page, index) => (
                        <Grid item key={index} xs={12} sm={12} md={6}>
                            <PageCard
                                key={page.label}
                                title={page.label}
                                description={page.description}
                                Icon={page.Icon}
                                link={page.link}
                            /></Grid>
                    ))
                }
            </Grid>
        </>
    );
}

export default Dashboard;
