import { Navigate } from 'react-router-dom';
import session from '../../auth/session/session';
import { KEYCLOAK_DISABLED } from '../../config';
import OldLogin from './components/OldLogin';

function Landing({ location }) {
    if (session.isAuthenticated()) {
        const toDashboard = !location?.state?.isRedirect || location.state.from === '/';

        return (
            <Navigate replace to={{
                pathname: toDashboard ? '/dashboard' : location.state.from,
                state: { from: location, isRedirect: true } }}
            />
        );
    }

    if (KEYCLOAK_DISABLED && !session.isAuthenticated()) {
        return <OldLogin />;
    }

    console.log('Redirecting to Keycloak login page...');

    return null;
}

export default Landing;
