import { Outlet, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import Faq from './faq/Faq';
import Manuals from './manuals/Manuals';
import Landing from './landing/Landing';
import Layout from '../components/Layout';
import session from '../auth/session/session';
import NoAccess from './error/NoAccess';
import Page404 from './error/404';

function AdminRoute() {
    return session.isNoAdmin() ? <NoAccess /> : <Outlet />;
}

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Landing />} />

            <Route path="/" element={<AdminRoute />}>
                <Route path="/" element={<Layout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/manuals" element={<Manuals />} />
                </Route>
            </Route>

            <Route path="*" element={<Page404 />} />
        </Routes>
    );
}

export default AppRoutes;
