import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import CenteredLoader from './CenteredLoader';

const StyledContainer = styled.div`
    position: relative;
`;
const StyledCenteredLoader = styled(({ isLoading, ...rest }) => <CenteredLoader {...rest} />)`
    position: absolute;
    visibility: hidden;
    
    ${({ isLoading }) => isLoading && css`
        visibility: initial;
    `}
`;
const StyledContentContainer = styled.div`
    visibility: inherit;
    
    ${({ isLoading }) => isLoading && css`
        visibility: hidden;
    `}
`;

function MaybeLoading({ isLoading, color, size, className, children }) {
    return (
        <StyledContainer className={className}>
            <StyledCenteredLoader isLoading={isLoading} color={color} size={size} />
            <StyledContentContainer isLoading={isLoading}>
                {children}
            </StyledContentContainer>
        </StyledContainer>
    );
}

MaybeLoading.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['small', 'large']),
    color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
};

MaybeLoading.defaultProps = {
    color: 'primary',
    size: 'large',
};

export default MaybeLoading;
